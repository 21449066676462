import _ from 'lodash';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { defineStore } from 'pinia';
import { computed, reactive, ref, unref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { SWAP_FORM_INPUTS } from '@/store/modules/swap/constants/SWAP_FORM_INPUTS';
import { SLIPPAGE_TOLERANCE, TRANSACTION_DEADLINE } from '@/helpers/constants';
import { DEFAULT_NETWORK_ID, SELECTED_NETWORK_NAME } from '@/helpers/networkParams.helper';
import { CROSSCHAIN_STEPS_NUMBER } from '@/store/modules/swap/constants/SWAP_NOTIFICATION';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { DEFAULT_CARDANO_CHAIN_ID } from '@/constants/DEFAULT_CARDANO_ID';
import { BIG_ZERO, max } from '@/utils/bigNumber';
import {
  MIN_ADA_FOR_BRIDGE_FROM_MILKOMEDA_IN_ADA,
  minDecimalsFromCardanoAndMilkomeda,
  normalizeWeiByMinDecimalsWhenExistCardanoToken,
} from '@/helpers/milkomeda-wrapped-smartcontract/milkomeda-wsc-calculation';
import { asyncFnCancelable } from '@/utils/promise';
import { getUniqueId } from '@/composables/utils/useUtils';
import { getSwapType, swapETHForWETH, swapMethod, swapWETHForETH } from './swap-methods';
import { fromWei, getScanLink } from '@/sdk/utils';
import { safeParseUnits } from '@/helpers/utils';
import {
  getErc20Contract,
  getRouterContract,
  getWethContract,
  transactionWithEstimatedGas,
} from '@/helpers/contract.helper';
import { getRouterAddress } from '@/helpers/address.helper';
import { ISwapForm } from '@/store/modules/swap/models/swap-form.interface';
import { Token } from '@/sdk/entities/token';
import { ITransactionSetting } from '@/store/modules/swap/models/transaction-setting.interface';
import {
  TransactionStatusResponse,
  TransactionStatusError,
  exactInRoutes,
  exactOutRoutes,
  fetchSwapTransactionStatus,
} from '@/helpers/cross-chain-api';
import { ISwapBestTrade } from '@/store/modules/swap/models/swap-best-trade.interface';
import { ChainId } from '@/sdk/constants';
import {
  INotification,
  INotificationStep,
  NotificationStatus,
} from '@/store/modules/notifications/models/notification.interface';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { useNotifications } from '@/store/modules/notifications/useNotifications';
import { useBalances } from '@/store/modules/tokens/useBalances';
import { useWallet } from '@/store/modules/wallet/useWallet';
import { useSwapMilkomedaWSCBridge } from '@/store/modules/swap/useSwapMilkomedaWSCBridge';
import { useSwapMilkomedaWSCUnwrapBridge } from '@/store/modules/swap/useSwapMilkomedaWSCUnwrapBridge';
import { useSpicePoints } from '@/composables/spice-points/useSpicePoints';
import { usePortfolios } from '@/composables/portfolios/usePortfolios';
import {
  getAmountsInWithPortfolios,
  getAmountsOutWithPortfolios,
} from './stable-swap-estimate-methods';

export const useSwap = defineStore('swap', () => {
  const { isPresentTokenIntoNetwork } = useTokens();
  const { updateTokenBalances } = useBalances();
  const { walletState } = useWallet();
  const { getPortfolioByAddress } = usePortfolios();
  const { updateSpicePointsBalance } = useSpicePoints();
  const { t } = useI18n();
  const {
    setIsEVMFromCardano,
    setDestinationAddress: setDestinationAddressWSC,
    setHasBridgeFromMilkomeda,
    setBridgeTokensFromCardano,
    setIsUnwrapADAFromMilkomeda,
    $reset: resetWSCWrapBridge,
  } = useSwapMilkomedaWSCBridge();
  const {
    milkomedaWSCUnwrapBridgeState,
    setIsEVMFromMilkomeda,
    setBridgeTokensFromMilkomeda,
    $reset: resetWSCUnwrapBridge,
  } = useSwapMilkomedaWSCUnwrapBridge();

  let bestTradeRequestController = new AbortController();

  const fireSwapFormRequest = ref<boolean>(false);
  const hasBestTradeAnswer = ref<boolean>(false);
  const swapFormUpdateRequest = ref<Generator<Promise<ISwapBestTrade>>>();
  const pendulumByUpdateWSCState = ref<boolean>(false);

  const swapForm = reactive<ISwapForm>({
    formLoading: false,
    formInfoLoading: false,
    input: {
      token: null,
      amount: null,
      estimated: false,
    },
    output: {
      token: null,
      amount: null,
      estimated: false,
    },
    estimatingInProgress: false,
    bestTradeRequestInProgress: false,
    bestTradeUpdateRequestInProgress: false,
    bestTrade: {} as ISwapBestTrade,
    settings: {
      slippageTolerance: SLIPPAGE_TOLERANCE.toString(),
      transactionDeadline: TRANSACTION_DEADLINE.toString(),
      enableMultiHops: true,
      localSwapPriority: true,
    },
    hasAllowance: false,
    allowanceRequestInProgress: false,
    errors: [],
    isShowSwapInfo: false,
    field: null,
    isSwapSameTokensFromMilkomedaToCardano: false,
  });

  const { addNotification } = useNotifications();

  const getApproveNotificationOptions = (
    status: NotificationStatus,
    tokenSymbol?: string,
    explorer?: string,
  ): INotification => {
    return {
      id: `approve_${tokenSymbol}`,
      status: status,
      content: t(`swap.notificationContent.approve.${status}`, {
        token: tokenSymbol,
      }),
      explorerLink: explorer,
    };
  };
  const getSwapNotificationOptions = (options: {
    status: NotificationStatus;
    id: string;
    cSwapForm: ISwapForm;
    hideExplorerLink?: boolean;
    explorerChainId?: ChainId;
    txHash?: string;
    step?: INotificationStep;
  }): INotification => {
    const hideExplorerLink =
      options.hideExplorerLink || (!options.hideExplorerLink && !options.txHash);
    const chainId =
      options.explorerChainId ?? options.step?.explorerChainId ?? options.step?.chainId;
    return {
      ...options,
      content: t(`swap.notificationContent.swap.${options.status}`, {
        input: options.cSwapForm.input.token?.symbol,
        output: options.cSwapForm.output.token?.symbol,
      }),
      //life: options.status !== 'inProgress',
      explorerLink:
        !hideExplorerLink && options.txHash
          ? getScanLink(options.txHash, 'transaction', chainId)
          : undefined,
    };
  };

  const resetSwapFormAmountsAndInProgress = () => {
    swapForm.field = null;
    swapForm.input.amount = null;
    swapForm.input.estimated = false;
    swapForm.output.amount = null;
    swapForm.output.estimated = false;
    swapForm.estimatingInProgress = false;
    swapForm.bestTrade = {} as ISwapBestTrade;
    swapForm.bestTradeRequestInProgress = false;
    swapForm.bestTradeUpdateRequestInProgress = false;
    swapForm.hasAllowance = false;
    swapForm.allowanceRequestInProgress = false;
    swapForm.isShowSwapInfo = false;
  };

  const isCrossChainRoute = (response: ISwapBestTrade): boolean => {
    return +response.route?.crossChainPortfolioIndex > -1;
  };

  const hasStableSwapIntoRoute = (response: ISwapBestTrade): boolean => {
    const portfoliosAddreses = response.route?.portfolios ?? [];

    return portfoliosAddreses.some(
      portfolioAddress => getPortfolioByAddress(portfolioAddress)?.isStableswap,
    );
  };

  const $reset = (noResetTokens?: boolean): void => {
    bestTradeRequestController.abort();
    swapFormUpdateRequest.value = undefined;

    if (!noResetTokens) {
      swapForm.input.token = null;
      swapForm.output.token = null;
    }

    resetSwapFormAmountsAndInProgress();
    swapForm.errors = [];
  };

  function isEmptyAmount() {
    return (
      (!swapForm.input.estimated && !swapForm.output.estimated) ||
      (swapForm.input.estimated && swapForm.output.amount === null) ||
      (swapForm.output.estimated && swapForm.input.amount === null)
    );
  }

  function resetSwapFormWhenHaveEmptyAmount() {
    if (isEmptyAmount()) {
      $reset(true);
    }
  }

  function resetWSCState() {
    resetWSCUnwrapBridge();
    resetWSCWrapBridge();
  }

  const setSettings = (settings: ITransactionSetting): void => {
    swapForm.settings = Object.assign(swapForm.settings, settings);
    fireSwapFormRequest.value = true;
  };

  const setEstimated = (field: SWAP_FORM_INPUTS): void => {
    swapForm[field].estimated = false;
    swapForm[getReverseField(field)].estimated = true;
  };

  const updateAmount = (amount: string | null, field: SWAP_FORM_INPUTS): void => {
    swapForm[field].amount = amount;
  };

  const setToken = (token: Token, field: SWAP_FORM_INPUTS): void => {
    const minDecimalsSwapFormField = swapForm[field].token
      ? minDecimalsFromCardanoAndMilkomeda(swapForm[field].token!)
      : 18;
    const minDecimalsSetToken = minDecimalsFromCardanoAndMilkomeda(token);

    if (minDecimalsSwapFormField > minDecimalsSetToken && swapForm[field].amount) {
      const bnAmount = new BigNumber(swapForm[field]?.amount || 0).toFixed(minDecimalsSetToken);
      updateAmount((+bnAmount).toString(), field);
    }
    swapForm[field].token = token;
    fireSwapFormRequest.value = true;
  };

  const setAmount = (amount: string, field: SWAP_FORM_INPUTS): void => {
    swapForm.field = field;
    const isAmountIsNotEmpty = !!amount.length && +amount !== 0;
    if (isAmountIsNotEmpty) {
      swapForm[field].amount = amount;
      setEstimated(field);
      fireSwapFormRequest.value = true;
    } else {
      $reset(true);
    }
  };

  function setDestinationAddress(walletAddress: string | null): void {
    swapForm.output.destinationAddress = walletAddress;
    setDestinationAddressWSC(walletAddress);
  }

  function abortPreviousBestTradeRequest() {
    const prevAbortController = bestTradeRequestController;
    bestTradeRequestController = new AbortController();
    prevAbortController.abort();
  }

  function* bestTradeRequestGenerator(swapFromForExactInOutRequest: ISwapForm) {
    const swapFormCopy = _.cloneDeep(swapFromForExactInOutRequest);
    while (true) {
      if (
        swapFormCopy.isSwapSameTokensFromMilkomedaToCardano ||
        isSwapETHForWETH(swapFormCopy) ||
        isSwapWETHForETH(swapFormCopy)
      ) {
        yield asyncFnCancelable<ISwapBestTrade>(
          () =>
            // NOTE: we use Promise and Timeout for UX - see skeleton on UI.
            new Promise<ISwapBestTrade>(resolve => {
              setTimeout(() => {
                resolve(buildSwapBestTradeWhenSwapSameTokensFromMilkomedaToCardano(swapFormCopy));
              }, 1000);
            }),
          bestTradeRequestController.signal,
        );
      } else if (swapFormCopy.output.estimated) {
        yield exactInRoutes(...prepareDataForExactInOutRequest(swapFormCopy, 'IN'), {
          signal: bestTradeRequestController.signal,
        });
      } else if (swapFormCopy.input.estimated) {
        yield exactOutRoutes(...prepareDataForExactInOutRequest(swapFormCopy, 'OUT'), {
          signal: bestTradeRequestController.signal,
        });
      }
    }
  }

  const prepareBestTradeRequest = (): void => {
    swapFormUpdateRequest.value = undefined;
    swapFormUpdateRequest.value = bestTradeRequestGenerator(swapForm);
  };

  const updateAmountFromBestTradeRequest = (response: ISwapBestTrade): void => {
    if (response.amountOut) {
      const tokenOut = swapForm.output.token!;
      const amountOutInWei = BigNumber(response.amountOut);
      const amountOutByMinDecimalsInWei = normalizeWeiByMinDecimalsWhenExistCardanoToken(
        amountOutInWei,
        tokenOut,
      );
      const amountOutByMinDecimalsInToken = fromWei(amountOutByMinDecimalsInWei, tokenOut.decimals);

      updateAmount(amountOutByMinDecimalsInToken.toString(), SWAP_FORM_INPUTS.INPUT_B);
    } else if (response.amountIn) {
      const tokenIn = swapForm.input.token!;
      const amountInInWei = BigNumber(response.amountIn);
      const amountInByMinDecimalsInWei = normalizeWeiByMinDecimalsWhenExistCardanoToken(
        amountInInWei,
        tokenIn,
      );
      const amountInByMinDecimalsInToken = fromWei(amountInByMinDecimalsInWei, tokenIn.decimals);

      updateAmount(amountInByMinDecimalsInToken.toString(), SWAP_FORM_INPUTS.INPUT_A);
    }
  };

  const setEstimatedForSwapFormAfterBestTradeRequest = (bestTradeResult: ISwapBestTrade) => {
    // NOTE:
    // user input value in this `field`.
    if (swapForm.field) {
      setEstimated(swapForm.field);
    }

    // NOTE:
    // need set estimated in `TO` when cross chain route.
    if (swapForm.input.estimated && isCrossChainRoute(bestTradeResult)) {
      setEstimated(SWAP_FORM_INPUTS.INPUT_A);
    }
  };

  const doBestTradeRequest = async () => {
    if (isEmptyAmount()) {
      return;
    }

    abortPreviousBestTradeRequest();

    // NOTE:
    // `swapFormUpdateRequest` contains copy of `swapForm`
    const bestTradeResult: ISwapBestTrade = await swapFormUpdateRequest.value?.next().value;

    console.log('doBestTradeRequest : result [ORIGIN] - ', bestTradeResult);
    const hasStableSwap = hasStableSwapIntoRoute(bestTradeResult);
    console.log('doBestTradeRequest : result has stable swap - ', hasStableSwap);
    const result = hasStableSwap
      ? await changeAmountEstimateWhenBestTradeHasStableSwap(swapForm, bestTradeResult)
      : bestTradeResult;
    console.log('doBestTradeRequest : result - ', result);
    updateAmountFromBestTradeRequest(result);

    swapForm.bestTrade = Object.assign({}, result);
    console.log('swapForm.bestTrade', swapForm.bestTrade);

    setEstimatedForSwapFormAfterBestTradeRequest(result);

    return result;
  };

  const setInProgressSwapFormChange = () => {
    swapForm.formInfoLoading = true; // TODO check
    swapForm.estimatingInProgress = true;
    swapForm.bestTradeRequestInProgress = true;
  };

  const setFinishSwapFormChange = () => {
    swapForm.formInfoLoading = false; // TODO check
    swapForm.estimatingInProgress = false;
    swapForm.bestTradeRequestInProgress = false;
  };

  const onSwapFormChange = async (manualUpdate?: boolean) => {
    setInProgressSwapFormChange();
    swapForm.bestTrade = {} as ISwapBestTrade;
    swapForm.errors = [];

    if (!manualUpdate) {
      prepareBestTradeRequest();
    }

    try {
      await doBestTradeRequest();
    } catch (err) {
      console.log('[SWAP] ERROR : ', err);
      if (err.name == 'AbortError') {
        return;
      }
      swapForm.errors.push('noRoute');
      swapForm.bestTrade = {} as ISwapBestTrade;
      if (swapForm.input.estimated) swapForm.input.amount = null;
      if (swapForm.output.estimated) swapForm.output.amount = null;
    }

    setFinishSwapFormChange();
    resetSwapFormWhenHaveEmptyAmount();
  };

  const doUpdateRequestByTimer = async () => {
    console.groupCollapsed('[SWAP] doUpdateRequestByTimer');
    console.log('bestTradeRequestInProgress', swapForm.bestTradeRequestInProgress);
    console.log('swapFormUpdateRequest.value', swapFormUpdateRequest.value);
    console.groupEnd();

    if (swapForm.bestTradeRequestInProgress) return;
    if (swapFormUpdateRequest.value === undefined) return;

    try {
      await doBestTradeRequest();
    } catch (err) {
      console.log('[SWAP] auto update error', err);
      if (err.name == 'AbortError') {
        return;
      }
    }
  };

  async function checkStateForSwap() {
    await checkAllowance();
  }

  async function checkAllowance() {
    console.log('checkAllowance', swapForm.input.token);
    if (!walletState.isInjected) return false;
    if (!swapForm.input.token || !swapForm.input.amount) return false;
    if (swapForm.input.token.isETHToken()) {
      swapForm.hasAllowance = true;
      return true;
    }
    try {
      const allowanceResult = await checkERC20Allowance(
        swapForm.input.token.address,
        walletState.wallets[SELECTED_NETWORK_NAME].account ?? '',
      );
      console.log(
        `allowanceResult [${swapForm.input.token.symbol}] : `,
        allowanceResult.toString(),
      );
      const allowanceAmount = fromWei(
        allowanceResult.toString(),
        swapForm.input.token?.decimals,
      ).toString();
      console.log(
        `allowanceAmount [${swapForm.input.token.symbol}] : `,
        allowanceAmount,
        swapForm.input.amount,
        +allowanceAmount >= +swapForm.input.amount,
      );
      swapForm.hasAllowance = +allowanceAmount >= +swapForm.input.amount;
    } catch (e) {
      swapForm.hasAllowance = false;
      throw Error(e);
    }
  }

  const setAllowance = async () => {
    console.log('setAllowance');
    if (!swapForm.input.token) return false;
    if (!swapForm.input.amount) return false;
    if (!walletState.isInjected) return false;

    swapForm.allowanceRequestInProgress = true;
    const tokenSymbol = swapForm.input.token.symbol;

    try {
      addNotification(getApproveNotificationOptions('inProgress', tokenSymbol));

      const tokenContract = getErc20Contract(
        swapForm.input.token.address,
        getInstance()?.web3?.getSigner(),
      );
      const result = await transactionWithEstimatedGas(tokenContract, 'approve', [
        getRouterAddress(),
        safeParseUnits(swapForm.input.amount, swapForm.input.token.decimals).toString(),
      ]);
      await result.wait();
      swapForm.hasAllowance = true;

      const explorerLink = getScanLink(
        result.hash,
        'transaction',
        ENABLE_FAKE_CARDANO_NETWORK ? +DEFAULT_NETWORK_ID! : undefined,
      );
      addNotification(getApproveNotificationOptions('success', tokenSymbol, explorerLink));
    } catch (error) {
      addNotification(getApproveNotificationOptions('error', tokenSymbol));
      throw error;
    } finally {
      swapForm.allowanceRequestInProgress = false;
    }
  };

  const onFormChange = async (manualUpdate?: boolean) => {
    if (checkIfSwapFormHasNoErrors(swapForm)) {
      await checkStateForSwap();
      await onSwapFormChange(manualUpdate);
    } else {
      bestTradeRequestController.abort();
      setFinishSwapFormChange();
      swapFormUpdateRequest.value = undefined;
      swapForm.bestTrade = {} as ISwapBestTrade;
      if (
        swapForm.errors.includes('sameTokens') ||
        swapForm.errors.includes('lessMinAmountForUnwrap')
      ) {
        resetSwapFormAmountsAndInProgress();
      } else {
        resetSwapFormWhenHaveEmptyAmount();
      }
    }
  };

  const switchInputs = async () => {
    if (swapForm.bestTradeRequestInProgress) {
      return;
    }

    if (swapForm.field) {
      setEstimated(swapForm.field);

      const newField =
        swapForm.field === SWAP_FORM_INPUTS.INPUT_A
          ? SWAP_FORM_INPUTS.INPUT_B
          : SWAP_FORM_INPUTS.INPUT_A;
      swapForm.field = newField;
    }

    [swapForm.input.token, swapForm.output.token] = [swapForm.output.token, swapForm.input.token];
    [swapForm.input.estimated, swapForm.output.estimated] = [
      swapForm.output.estimated,
      swapForm.input.estimated,
    ];
    [swapForm.input.amount, swapForm.output.amount] = [
      swapForm.input.estimated ? null : swapForm.output.amount,
      swapForm.output.estimated ? null : swapForm.input.amount,
    ];
    swapForm.hasAllowance = false;
    fireSwapFormRequest.value = true;
  };

  function updateWSCState() {
    pendulumByUpdateWSCState.value = !pendulumByUpdateWSCState.value;
  }

  // Update swap form when was changes
  watch(
    () => fireSwapFormRequest.value,
    async val => {
      if (unref(val)) {
        fireSwapFormRequest.value = false;
        await onFormChange();
      }
    },
  );

  // Check state for swap when changed input
  watch([() => swapForm.input.amount, pendulumByUpdateWSCState], async ([val]) => {
    if (val) {
      await checkStateForSwap();
    } else {
      swapForm.hasAllowance = false;
      if (ENABLE_FAKE_CARDANO_NETWORK) {
        resetWSCWrapBridge();
      }
    }
  });

  // Set data for bridge when changed input
  watch(
    [
      () => swapForm.input.amount,
      () => swapForm.input.token,
      () => milkomedaWSCUnwrapBridgeState.needRevertBridge,
      () => swapForm.isSwapSameTokensFromMilkomedaToCardano,
      pendulumByUpdateWSCState,
    ],
    ([inputAmount, inputToken, needRevertBridge]) => {
      if (!ENABLE_FAKE_CARDANO_NETWORK) return;

      if (inputAmount && inputToken) {
        // Wrap bridge
        setIsEVMFromCardano(inputToken.chainId !== swapForm.output.token?.chainId);
        setHasBridgeFromMilkomeda(
          needRevertBridge &&
            !(
              swapForm.isSwapSameTokensFromMilkomedaToCardano && swapForm.output.token?.isETHToken()
            ),
        );
        setBridgeTokensFromCardano([{ amount: inputAmount.toString(), token: inputToken }]);
        setIsUnwrapADAFromMilkomeda(
          swapForm.isSwapSameTokensFromMilkomedaToCardano && !!swapForm.output.token?.isETHToken(),
        );
      } else {
        resetWSCWrapBridge();
      }
    },
  );

  // Set data for unwrap bridge when changed output
  watch(
    [() => swapForm.output.amount, () => swapForm.output.token, pendulumByUpdateWSCState],
    ([outputAmount, outputToken]) => {
      if (!ENABLE_FAKE_CARDANO_NETWORK) return;

      if (
        outputAmount &&
        outputToken &&
        isPresentTokenIntoNetwork(outputToken.symbol!, +DEFAULT_CARDANO_CHAIN_ID)
      ) {
        // Unwrap bridge
        setIsEVMFromMilkomeda(swapForm.input.token?.chainId !== outputToken.chainId);
        if (swapForm.isSwapSameTokensFromMilkomedaToCardano && outputToken.isETHToken()) {
          outputAmount = BigNumber(outputAmount)
            .minus(MIN_ADA_FOR_BRIDGE_FROM_MILKOMEDA_IN_ADA)
            .toString();
          outputAmount = max(outputAmount, BIG_ZERO).toString();
        }
        setBridgeTokensFromMilkomeda([{ amount: outputAmount, token: outputToken }]);
      } else {
        resetWSCUnwrapBridge();
      }
    },
  );

  // Check swap same tokens from Milkomeda to Cardano
  watch([() => swapForm.input.token, () => swapForm.output.token], ([inputToken, outputToken]) => {
    swapForm.isSwapSameTokensFromMilkomedaToCardano = false;

    if (!inputToken || !outputToken) return;

    swapForm.isSwapSameTokensFromMilkomedaToCardano =
      ENABLE_FAKE_CARDANO_NETWORK &&
      inputToken.isSameSymbol(outputToken) &&
      isPresentTokenIntoNetwork(inputToken.symbol!, +DEFAULT_CARDANO_CHAIN_ID);
  });

  const doSwap = async () => {
    console.groupCollapsed('[SWAP] doSwap');
    const cSwapForm: ISwapForm = _.cloneDeep(swapForm);
    const account: string = cSwapForm.output.destinationAddress
      ? cSwapForm.output.destinationAddress
      : walletState.wallets[SELECTED_NETWORK_NAME].account ?? '';
    const isCrossChainSwap = +cSwapForm.bestTrade.route.crossChainPortfolioIndex !== -1;
    const id = getUniqueId();
    let resultCrossChain: TransactionStatusResponse | undefined;
    let step: INotificationStep = {
      current: 1,
      total: CROSSCHAIN_STEPS_NUMBER,
      chainId: cSwapForm.input.token?.chainId,
    };

    try {
      addNotification(
        getSwapNotificationOptions({
          id,
          status: 'inProgress',
          cSwapForm,
          step: isCrossChainSwap ? { ...step } : undefined,
          hideExplorerLink: true,
        }),
      );
      console.log(`waiting 'swap' transaction`);
      const transactionResponse = await createSwapTransaction(cSwapForm, account);
      console.log(`has 'swap' transaction : `, transactionResponse);

      const hashTx = transactionResponse.hash;
      const explorerChainIdForSwapTx =
        !isCrossChainSwap && ENABLE_FAKE_CARDANO_NETWORK ? +DEFAULT_NETWORK_ID! : undefined;

      addNotification(
        getSwapNotificationOptions({
          id,
          status: 'inProgress',
          cSwapForm,
          step: isCrossChainSwap ? { ...step } : undefined,
          txHash: hashTx,
          explorerChainId: explorerChainIdForSwapTx,
        }),
      );

      if (!ENABLE_FAKE_CARDANO_NETWORK) {
        $reset(true);
      }

      console.log(`waiting block for 'swap'`);
      const swapBlock = await transactionResponse.wait();
      console.log(`has block for 'swap' : `, swapBlock);

      addNotification(
        getSwapNotificationOptions({
          id,
          status: 'success',
          cSwapForm,
          step: isCrossChainSwap ? { ...step } : undefined,
          hideExplorerLink: !hashTx,
          txHash: hashTx,
          explorerChainId: explorerChainIdForSwapTx,
        }),
      );

      if (isCrossChainSwap) {
        step = Object.assign(step, {
          current: 2,
          chainId: cSwapForm.output.token?.chainId,
          explorerChainId: cSwapForm.output.token?.chainId,
        });
        addNotification(
          getSwapNotificationOptions({
            id,
            status: 'inProgress',
            hideExplorerLink: true,
            cSwapForm,
            step: { ...step },
          }),
        );

        resultCrossChain = await fetchSwapTransactionStatus(
          DEFAULT_NETWORK_ID as unknown as ChainId,
          hashTx,
        );
        console.log('resultCrossChain', resultCrossChain);
        addNotification(
          getSwapNotificationOptions({
            id,
            txHash: resultCrossChain.transactionStatus.txHashDestination,
            status: 'success',
            cSwapForm,
            step: { ...step },
          }),
        );
      }

      await updateTokenBalances();
      await updateSpicePointsBalance();
    } catch (error) {
      console.error(`[SWAP] Happen error during swap operation. ERROR : `, error);
      let providerRpcError = error;
      if (error.error?.name === 'ProviderRpcError') {
        providerRpcError = error.error;
      }
      if (providerRpcError.name === 'ProviderRpcError') {
        console.error(`[ERROR] ProviderRpcError. Error details : `, {
          code: providerRpcError.code,
          data: providerRpcError.data,
        });
      }
      if (error instanceof TransactionStatusError) {
        resultCrossChain = error.cause;
      }
      addNotification(
        getSwapNotificationOptions({
          id,
          txHash:
            isCrossChainSwap && resultCrossChain
              ? resultCrossChain.transactionStatus.txHashDestination
              : undefined,
          status: 'error',
          hideExplorerLink:
            isCrossChainSwap && resultCrossChain?.transactionStatus?.txHashDestination === '',
          cSwapForm,
          step: isCrossChainSwap ? { ...step } : undefined,
        }),
      );
      throw error;
    } finally {
      console.groupEnd();
    }
  };

  return {
    swapForm,
    setToken,
    setAmount,
    setDestinationAddress,
    swapSettings: computed(() => swapForm.settings),
    hasBestTradeAnswer: computed(() => hasBestTradeAnswer.value),
    setSettings,
    onFormChange,
    setAllowance,
    doSwap,
    $reset,
    resetWSCState,
    updateWSCState,
    switchInputs,
    doUpdateRequestByTimer,
  };
});

function getInstanceOfRouterContract() {
  console.log('[SWAP] Signer for smart contract : ', getInstance().web3.getSigner());

  return getRouterContract(getRouterAddress(), getInstance().web3.getSigner());
}

function getReverseField(field: SWAP_FORM_INPUTS) {
  return field === SWAP_FORM_INPUTS.INPUT_A ? SWAP_FORM_INPUTS.INPUT_B : SWAP_FORM_INPUTS.INPUT_A;
}

async function checkERC20Allowance(address: string, account: string): Promise<any> {
  console.log('checkERC20Allowance', address);
  const tokenContract = getErc20Contract(address, getInstance()?.web3?.getSigner());
  console.log('tokenContract', tokenContract);
  return await tokenContract.allowance(account, getRouterAddress());
}

function validatePositiveNumber(value: string): boolean {
  try {
    const bnValue = new BigNumber(value);
    if (bnValue.gt(0)) return true;
  } catch (e) {
    return false;
  }
  return false;
}

function checkIfSwapFormHasNoErrors(swapForm: ISwapForm): boolean {
  console.log('checkIfSwapFormHasNoErrors', swapForm);
  swapForm.errors = [];
  if (
    !swapForm.isSwapSameTokensFromMilkomedaToCardano &&
    swapForm.input.token &&
    swapForm.output.token
  ) {
    if (swapForm.input.token.isSameSymbol(swapForm.output.token)) {
      swapForm.errors.push('sameTokens');
      return false;
    }
  }
  if (!swapForm.input.token) return false;
  if (!swapForm.output.token) return false;
  if (!swapForm.input.estimated && !swapForm.output.estimated) return false;
  if (
    swapForm.input.estimated &&
    swapForm.output.amount &&
    !validatePositiveNumber(swapForm.output.amount)
  )
    return false;
  if (
    swapForm.output.estimated &&
    swapForm.input.amount &&
    !validatePositiveNumber(swapForm.input.amount)
  )
    return false;

  const swapAmount = swapForm.input.estimated ? swapForm.output.amount : swapForm.input.amount;
  if (
    swapForm.isSwapSameTokensFromMilkomedaToCardano &&
    swapForm.input.token &&
    swapForm.input.token.isETHToken() &&
    swapAmount
  ) {
    if (+swapAmount < +MIN_ADA_FOR_BRIDGE_FROM_MILKOMEDA_IN_ADA) {
      swapForm.errors.push('lessMinAmountForUnwrap');
      return false;
    }
  }
  return true;
}

function prepareDataForExactInOutRequest(
  swapForm: ISwapForm,
  requestType: 'IN' | 'OUT',
): [ChainId, string, ChainId, string, string, 1 | 2 | 3, boolean] {
  return [
    swapForm.input.token!.chainId,
    swapForm.input.token!.address,
    swapForm.output.token!.chainId,
    swapForm.output.token!.address,
    requestType === 'IN'
      ? safeParseUnits(swapForm.input.amount, swapForm.input.token!.decimals)
      : safeParseUnits(swapForm.output.amount, swapForm.output.token!.decimals),
    swapForm.settings.enableMultiHops ? 3 : 1,
    !!swapForm.settings.localSwapPriority,
  ];
}

function buildSwapBestTradeWhenSwapSameTokensFromMilkomedaToCardano(
  swapForm: ISwapForm,
): ISwapBestTrade {
  const swapToken = swapForm.output.estimated ? swapForm.input : swapForm.output;
  const estimatedAmount = safeParseUnits(swapToken.amount, swapToken.token!.decimals);

  return {
    route: {
      path: [swapToken.token!.address],
      chains: [swapToken.token!.chainId.toString()],
      portfolios: [],
      crossChainPortfolioIndex: '-1',
      portfolioNames: [],
    },
    amountOut: swapForm.output.estimated ? estimatedAmount : undefined,
    amountIn: swapForm.input.estimated ? estimatedAmount : undefined,
    priceImpact: '0',
    lpFee: '0',
    crossChainFee: '0',
  };
}

// Stable swap
async function changeAmountEstimateWhenBestTradeHasStableSwap(
  swapForm: ISwapForm,
  bestTradeResult: ISwapBestTrade,
): Promise<ISwapBestTrade> {
  const routerContract = getInstanceOfRouterContract();
  const swapFormForEstimate: ISwapForm = {
    ...swapForm,
    bestTrade: bestTradeResult,
  };

  if (bestTradeResult.amountOut) {
    const { amounts } = await getAmountsOutWithPortfolios(swapFormForEstimate, routerContract);

    return {
      ...bestTradeResult,
      amountOut: amounts.at(-1)?.toString(),
    };
  }

  if (bestTradeResult.amountIn) {
    const { amounts } = await getAmountsInWithPortfolios(swapFormForEstimate, routerContract);

    return {
      ...bestTradeResult,
      amountIn: amounts.at(0)?.toString(),
    };
  }

  return bestTradeResult;
}

// Swaps
function createSwapTransaction(
  swapForm: ISwapForm,
  account: string,
): Promise<ethers.providers.TransactionResponse> {
  const routerContract = getInstanceOfRouterContract();

  if (isSwapETHForWETH(swapForm)) {
    if (swapForm.output.token) {
      const wethContract = getWethContract(
        swapForm.output.token?.address,
        getInstance().web3.getSigner(),
      );
      return swapETHForWETH(swapForm, wethContract);
    }
  }

  if (isSwapWETHForETH(swapForm)) {
    if (swapForm.input.token) {
      const wethContract = getWethContract(
        swapForm.input.token?.address,
        getInstance().web3.getSigner(),
      );
      return swapWETHForETH(swapForm, wethContract);
    }
  }

  const swapType = getSwapType(swapForm);
  return swapMethod[swapType](swapForm, account, routerContract);
}

function isSwapETHForWETH(swapForm: ISwapForm): boolean {
  if (!swapForm.input.token || !swapForm.output.token) return false;
  return swapForm.input.token?.isETHToken() && swapForm.output.token?.isWETHToken();
}

function isSwapWETHForETH(swapForm: ISwapForm): boolean {
  if (!swapForm.input.token || !swapForm.output.token) return false;
  return swapForm.input.token?.isWETHToken() && swapForm.output.token?.isETHToken();
}
