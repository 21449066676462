import { defineStore } from 'pinia';
import BigNumber from 'bignumber.js';
import { computed, ref, watch } from 'vue';
import { UPDATE_INTERVAL } from '@/helpers/constants';
import { APP_NETWORK_NAME } from '@/helpers/networkParams.helper';
import { useWallet } from '@/store/modules/wallet/useWallet';
import { fetchSpicePointsBalance } from '@/helpers/analytic-api';
import { BIG_ZERO } from '@/utils/bigNumber';

export const useSpicePoints = defineStore('spicePoints', () => {
  const { walletState } = useWallet();

  let updateIntervalId: NodeJS.Timeout | undefined = undefined;

  const spicePointsAmount = ref<BigNumber>(BIG_ZERO);

  const isEnabledSpicePoints = computed(
    () =>
      walletState.isInjected &&
      walletState.isNetworkSupported &&
      walletState.wallets[APP_NETWORK_NAME]?.account &&
      APP_NETWORK_NAME === 'Bob_Chain',
  );

  async function updateSpicePointsBalance(): Promise<void> {
    const walletAddress = walletState.wallets[APP_NETWORK_NAME]?.account;

    console.log('[SPICE:POINTS:UPDATE] Spice points balance updating.');
    if (!isEnabledSpicePoints.value) {
      return;
    }

    try {
      const spicePointsResponse = await fetchSpicePointsBalance(walletAddress);
      spicePointsAmount.value = BigNumber(spicePointsResponse ?? 0);
    } catch (ex) {
      console.error('[SPICE:POINTS:UPDATE] Error: ', ex);
    }
  }

  function initAutoUpdateSpicePointsBalance(): void {
    stopAutoUpdateSpicePointsBalance();

    updateIntervalId = setInterval(() => updateSpicePointsBalance(), UPDATE_INTERVAL);
    console.info('[SPICE:POINTS:UPDATE] Spice points balance update enabled.');
  }

  function stopAutoUpdateSpicePointsBalance(): void {
    if (!updateIntervalId) return;

    clearInterval(updateIntervalId);
    updateIntervalId = undefined;
    console.info('[SPICE:POINTS:UPDATE] Spice points balance update disabled.');
  }

  watch(
    () => walletState.wallets[APP_NETWORK_NAME]?.account,
    account => {
      if (account && isEnabledSpicePoints.value) {
        updateSpicePointsBalance();
      }
    },
    { immediate: true },
  );

  return {
    isEnabledSpicePoints,
    spicePointsAmount: computed(() => <BigNumber>spicePointsAmount.value),
    updateSpicePointsBalance,
    initAutoUpdateSpicePointsBalance,
    stopAutoUpdateSpicePointsBalance,
  };
});
