import _ from 'lodash';
import { computed, reactive } from 'vue';
import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';
import { DEFAULT_CARDANO_CHAIN_ID } from '@/constants/DEFAULT_CARDANO_ID';
import { fromWei, getScanLink } from '@/sdk/utils';
import { calculateGasForReversBridge } from '@/helpers/milkomeda-wrapped-smartcontract/milkomeda-wsc-calculation';
import { Token } from '@/sdk/entities/token';
import { AutoStaking } from '@/sdk/entities/autoStaking';
import { IStakingClaimForm } from '@/store/modules/staking/models/staking-claim-form.interface';
import { BridgeToken } from '@/composables/milkomeda-wrapped-smartcontract/models/bridge-token';
import { ChainId } from '@/sdk/constants';
import {
  INotification,
  INotificationStep,
  NotificationStatus,
} from '@/store/modules/notifications/models/notification.interface';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { useStakingMilkomedaWSCBridge } from '@/store/modules/staking/useStakingMilkomedaWSCBridge';
import { useStakingMilkomedaWSCUnwrapBridge } from '@/store/modules/staking/useStakingMilkomedaWSCUnwrapBridge';
import { useAutoStakingTransactions } from '@/composables/staking/auto/useAutoStakingTransactions';
import { useNotifications } from '@/store/modules/notifications/useNotifications';

export const useStakingClaim = defineStore('stakingClaim', () => {
  const { t } = useI18n();
  const { getTokenBySymbolAndChainId, isPresentTokenIntoNetwork } = useTokens();
  const {
    setBridgeTokensFromCardano,
    setIsEVMFromCardano,
    setHasBridgeFromMilkomeda,
    $reset: resetMilkomedaWSCBridge,
  } = useStakingMilkomedaWSCBridge();
  const {
    setBridgeTokensFromMilkomeda,
    setIsEVMFromMilkomeda,
    $reset: resetMilkomedaWSCUnwrapBridge,
  } = useStakingMilkomedaWSCUnwrapBridge();
  const { claim } = useAutoStakingTransactions();
  const { addNotification } = useNotifications();

  const stakingClaimForm = reactive<IStakingClaimForm>({
    pool: null,
    token: null,
  });

  const cardanoADATokenForBridge = computed<Token>(() => {
    return getTokenBySymbolAndChainId('ADA', +DEFAULT_CARDANO_CHAIN_ID);
  });

  function $reset(): void {
    stakingClaimForm.pool = null;
    stakingClaimForm.token = null;
  }

  function checkClaimForBridgeFromMilkomeda(amountInWei: string, token: Token) {
    if (!ENABLE_FAKE_CARDANO_NETWORK) return;
    resetMilkomedaWSCBridge();
    resetMilkomedaWSCUnwrapBridge();

    const bridgeTokens: BridgeToken[] = [];
    if (isPresentTokenIntoNetwork(token.symbol!, +DEFAULT_CARDANO_CHAIN_ID)) {
      bridgeTokens.push({
        amount: fromWei(amountInWei, token.decimals).toString(),
        token: token,
      });
    }

    setIsEVMFromCardano(false);
    setHasBridgeFromMilkomeda(!!bridgeTokens.length);

    if (bridgeTokens.length) {
      setIsEVMFromMilkomeda(false);
      setBridgeTokensFromMilkomeda(bridgeTokens);
    } else {
      resetMilkomedaWSCUnwrapBridge();
    }

    const gas = calculateGasForReversBridge(cardanoADATokenForBridge.value, bridgeTokens);
    setBridgeTokensFromCardano(
      gas ? [gas] : [{ amount: '0', token: cardanoADATokenForBridge.value }],
    );
  }

  function setClaimFromPool(pool: AutoStaking, token: Token): void {
    stakingClaimForm.pool = pool;
    stakingClaimForm.token = token;
  }

  async function doClaim() {
    console.groupCollapsed('[AUTO:POOL:CLAIM] doClaim');
    const cStakingClaimForm: IStakingClaimForm = _.cloneDeep(stakingClaimForm);

    if (!cStakingClaimForm.pool) return;

    const notificationId = 'auto_bounty_claim';
    const claimingContent = `${t('claimingAutoBounty')}`;

    try {
      addNotification(
        getClaimNotificationOptions({
          id: notificationId,
          status: 'inProgress',
          content: claimingContent,
          hideExplorerLink: true,
        }),
      );

      console.log(`waiting 'claim' transaction`);
      const transactionReceipt = await claim();
      console.log(`has 'claim' transaction : `, transactionReceipt);

      addNotification(
        getClaimNotificationOptions({
          id: notificationId,
          status: 'success',
          content: `${claimingContent} ${t('succeeded')}`,
          hideExplorerLink: false,
          hashTx: transactionReceipt.transactionHash,
          explorerChainId: ENABLE_FAKE_CARDANO_NETWORK ? +DEFAULT_NETWORK_ID! : undefined,
        }),
      );
    } catch (error) {
      console.error(`[AUTO:POOL:CLAIM] Happen error during claim operation. ERROR : `, error);
      if (error.name === 'ProviderRpcError') {
        console.error(`[ERROR] ProviderRpcError. Error details : `, {
          code: error.code,
          data: error.data,
        });
      }
      addNotification(
        getClaimNotificationOptions({
          id: notificationId,
          status: 'error',
          content: `${claimingContent} ${t('failed')}`,
          hideExplorerLink: true,
        }),
      );

      throw error;
    } finally {
      console.groupEnd();
    }
  }

  // NOTIFICATIONS

  function getClaimNotificationOptions(options: {
    status: NotificationStatus;
    id: string;
    content: string;
    hashTx?: string;
    step?: INotificationStep;
    hideExplorerLink?: boolean;
    explorerChainId?: ChainId;
  }): INotification {
    const showLink = !!options.hashTx && !options.hideExplorerLink;
    const hashTx = options.hashTx ?? '';
    const explorerChainId = options.explorerChainId ?? options.step?.chainId;
    return {
      ...options,
      explorerLink: showLink ? getScanLink(hashTx, 'transaction', explorerChainId) : undefined,
    };
  }
  // ====

  return {
    stakingClaimForm,
    checkClaimForBridgeFromMilkomeda,
    setClaimFromPool,
    doClaim,
    $reset,
  };
});
