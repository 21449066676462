import { reactive, watch, computed } from 'vue';
import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import BigNumber from 'bignumber.js';
import { ChainId } from '@/sdk/constants';
import { useNotifications } from '@/store/modules/notifications/useNotifications';
import { Portfolio } from '@/sdk/entities/portfolio';
import { EasyModeWithdrawForm } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/models/easy-mode-form';
import { useEasyModeTransactions } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/composables/useEasyModeTransactions';
import { fetchSwapTransactionStatus } from '@/helpers/cross-chain-api';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { Token } from '@/sdk/entities/token';
import { checkERC20Allowance } from '@/helpers/token.helper';
import { getRouterAddress } from '@/helpers/address.helper';
import { fromWei, getScanLink, toWei } from '@/sdk/utils';
import { getErc20Contract, transactionWithEstimatedGas } from '@/helpers/contract.helper';
import { PortfolioSource } from '@/sdk/entities/PortfolioSource';
import {
  INotification,
  INotificationStep,
  NotificationStatus,
} from '@/store/modules/notifications/models/notification.interface';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { useWallet } from '@/store/modules/wallet/useWallet';
import { usePortfoliosMilkomedaWSCBridge } from '@/store/modules/portfolios/usePortfoliosMilkomedaWSCBridge';
import { usePortfoliosMilkomedaWSCUnwrapBridge } from '@/store/modules/portfolios/usePortfoliosMilkomedaWSCUnwrapBridge';
import { useEasyModeForm } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/composables/useEasyModeForm';
import { getWithdrawMethodTokenParameters } from '@/helpers/portfolioWithdraw.helper';
import { BridgeToken } from '@/composables/milkomeda-wrapped-smartcontract/models/bridge-token';
import { DEFAULT_CARDANO_CHAIN_ID } from '@/constants/DEFAULT_CARDANO_ID';
import { DEFAULT_NETWORK_ID, SELECTED_NETWORK_NAME } from '@/helpers/networkParams.helper';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { calculateGasForReversBridge } from '@/helpers/milkomeda-wrapped-smartcontract/milkomeda-wsc-calculation';
import { getUniqueId } from '@/composables/utils/useUtils';
import { useFarmingTransactions } from '@/composables/farming/useFarmingTransactions';

export interface IWithdrawForm {
  selectedTokens: { token: Token; amount: BigNumber }[] | null;
  tokens: Token[];
  amountsOut: string[]; // In Token WEI
  amountsLPOut: string[]; // In LP WEI
  needApproveLP: boolean;
}

export const useEasyModeWithdraw = defineStore('easyModeWithdraw', () => {
  const { t } = useI18n();
  const { getTokenBySymbolAndChainId, isPresentTokenIntoNetwork } = useTokens();
  const { walletState } = useWallet();
  const { easyModeForm: originEasyModeForm, needDestinationAddress } = useEasyModeForm();
  const {
    setBridgeTokensFromCardano,
    setDestinationAddress: setDestinationAddressWSC,
    setIsEVMFromCardano,
    setHasBridgeFromMilkomeda,
    $reset: resetMilkomedaWSCBridge,
  } = usePortfoliosMilkomedaWSCBridge();
  const {
    setBridgeTokensFromMilkomeda,
    setIsEVMFromMilkomeda,
    $reset: resetMilkomedaWSCUnwrapBridge,
  } = usePortfoliosMilkomedaWSCUnwrapBridge();
  const { withdraw, delegateWithdraw, updatePortfolios } = useEasyModeTransactions();
  const { updateFarms } = useFarmingTransactions();
  const { addNotification } = useNotifications();

  const withdrawForm = reactive<IWithdrawForm>({
    selectedTokens: null,
    tokens: [],
    amountsOut: [], // In WEI
    amountsLPOut: [], // In LP WEI
    needApproveLP: true,
  });

  function $reset(): void {
    withdrawForm.needApproveLP = true;
    withdrawForm.selectedTokens = null;
    withdrawForm.tokens = [];
    withdrawForm.amountsOut = [];
    withdrawForm.amountsLPOut = [];
    resetMilkomedaWSCBridge();
    resetMilkomedaWSCUnwrapBridge();
  }

  const easyModeForm = <EasyModeWithdrawForm>originEasyModeForm;

  const getApproveNotificationOptions = (
    status: NotificationStatus,
    explorerLink?: string,
  ): INotification => {
    const APPROVING_LP = 'Approving LP tokens';
    return {
      id: `approve_${easyModeForm.portfolio.name}`,
      status: status,
      content: APPROVING_LP,
      explorerLink,
    };
  };

  const getWithdrawNotificationOptions = (options: {
    status: NotificationStatus;
    id: string;
    content: string;
    step?: INotificationStep;
    hideExplorerLink?: boolean;
    explorerChainId?: ChainId;
    hashTx?: string;
  }): INotification => {
    const showLink = options.id && !options.hideExplorerLink;
    const explorerChainId = options.explorerChainId ?? options.step?.chainId;
    return {
      ...options,
      explorerLink:
        showLink && options.hashTx
          ? getScanLink(options.hashTx, 'transaction', explorerChainId)
          : undefined,
    };
  };

  const cardanoADATokenForBridge = computed<Token>(() => {
    return getTokenBySymbolAndChainId('ADA', +DEFAULT_CARDANO_CHAIN_ID);
  });

  const milkomedaLPToken = computed<Token>(() => {
    return easyModeForm.portfolio.lpToken;
  });

  function setSelectedTokens(selectedTokens: { token: Token; amount: BigNumber }[]): void {
    resetMilkomedaWSCBridge();
    resetMilkomedaWSCUnwrapBridge();
    if (!selectedTokens.length) return;

    setIsEVMFromCardano(needDestinationAddress.value);

    const { tokens, amountsOutInWei, amountsLPOutInWei } = getWithdrawMethodTokenParameters(
      selectedTokens,
      easyModeForm.lpTokenAmount,
      easyModeForm,
    );

    withdrawForm.selectedTokens = selectedTokens;
    withdrawForm.tokens = tokens;
    withdrawForm.amountsOut = amountsOutInWei;
    withdrawForm.amountsLPOut = amountsLPOutInWei;

    const bridgeTokens: BridgeToken[] = [];
    tokens.forEach((token, index) => {
      if (isPresentTokenIntoNetwork(token.symbol!, +DEFAULT_CARDANO_CHAIN_ID)) {
        bridgeTokens.push({
          amount: fromWei(amountsOutInWei[index], token.decimals).toString(),
          token: token,
        });
      }
    });

    if (bridgeTokens.length) {
      setIsEVMFromMilkomeda(needDestinationAddress.value);
      setBridgeTokensFromMilkomeda(bridgeTokens);
    } else {
      resetMilkomedaWSCUnwrapBridge();
    }

    if (!needDestinationAddress.value) {
      setHasBridgeFromMilkomeda(!!bridgeTokens.length);

      const bridgeFromCardanoTokens: BridgeToken[] = [];
      const gas = calculateGasForReversBridge(cardanoADATokenForBridge.value, bridgeTokens);
      if (gas) {
        bridgeFromCardanoTokens.push(gas);
      }
      // Check LP into Cardano
      if (isPresentTokenIntoNetwork(easyModeForm.lpToken.symbol!, +DEFAULT_CARDANO_CHAIN_ID)) {
        bridgeFromCardanoTokens.push({
          amount: easyModeForm.lpTokenAmount.toString(),
          token: easyModeForm.lpToken,
        });
      }
      setBridgeTokensFromCardano(bridgeFromCardanoTokens);
    }
  }

  async function checkAllowance() {
    console.groupCollapsed('[PORTFOLIOS:WITHDRAW] checkAllowance');
    if (!walletState.isInjected) return false;

    let needApprove = true;

    const LPSumInWei = toWei(easyModeForm.lpTokenAmount, milkomedaLPToken.value.decimals);

    try {
      const allowanceResultInWei = await checkERC20Allowance(
        milkomedaLPToken.value,
        walletState.wallets[SELECTED_NETWORK_NAME].account ?? '',
        getRouterAddress(),
      );
      console.log('allowance result [ LP WEI ]', allowanceResultInWei.toString());
      console.log('LP out [ LP WEI ]', LPSumInWei.toFixed());

      needApprove = allowanceResultInWei.isZero() || allowanceResultInWei.lt(LPSumInWei);
    } catch (err) {
      console.error(`[PORTFOLIO:WITHDRAW:CHECK_ALLOWANCE] ERROR : `, err);
      needApprove = true;
    }

    withdrawForm.needApproveLP = needApprove;

    console.log('need approve LP : ', withdrawForm.needApproveLP);

    console.groupEnd();
  }

  async function setAllowance() {
    console.log('[PORTFOLIO:WITHDRAW] setAllowance');
    if (!walletState.isInjected) return false;
    if (!withdrawForm.needApproveLP) return false;

    const LP = milkomedaLPToken.value;
    const amountLPInWei = toWei(
      easyModeForm.lpTokenAmount.toFixed(0, BigNumber.ROUND_UP),
      LP.decimals,
    );

    try {
      addNotification(getApproveNotificationOptions('inProgress'));

      const tokenContract = getErc20Contract(
        milkomedaLPToken.value.address,
        getInstance()?.web3.getSigner(),
      );

      const result = await transactionWithEstimatedGas(tokenContract, 'approve', [
        getRouterAddress(),
        amountLPInWei.toFixed(0, BigNumber.ROUND_UP),
      ]);

      await result.wait();

      const explorerLink = getScanLink(
        result.hash,
        'transaction',
        ENABLE_FAKE_CARDANO_NETWORK ? +DEFAULT_NETWORK_ID! : undefined,
      );
      addNotification(getApproveNotificationOptions('success', explorerLink));
    } catch (error) {
      console.error(
        `[PORTFOLIO:WITHDRAW:SET_ALLOWANCE] Can not approve token [${LP.symbol} | ${LP.address}]. ERROR: `,
        error,
      );
      addNotification(getApproveNotificationOptions('error'));

      throw error;
    }
  }

  async function withdrawLocal(
    portfolio: Portfolio,
    withdrawTokens: { token: Token; amount: BigNumber }[],
    account: string,
  ): Promise<void> {
    console.groupCollapsed('[PORTFOLIO:WITHDRAW] withdrawLocal');

    const id = getUniqueId();
    const withdrawingLiquidity = `${t('withdrawingLiquidity')} ${t('from')} ${portfolio.name}`;

    try {
      addNotification(
        getWithdrawNotificationOptions({
          id,
          status: 'inProgress',
          content: withdrawingLiquidity,
          hideExplorerLink: true,
        }),
      );

      console.log(`waiting 'withdraw' transaction`);
      const transactionResponse = await withdraw(
        withdrawTokens,
        easyModeForm.lpTokenAmount,
        account,
      );
      console.log(`has 'withdraw' transaction : `, transactionResponse);

      await updatePortfolios();

      const hashTx = transactionResponse.transactionHash;
      addNotification(
        getWithdrawNotificationOptions({
          id,
          status: 'success',
          content: `${withdrawingLiquidity} ${t('succeeded')}`,
          hideExplorerLink: false,
          explorerChainId: ENABLE_FAKE_CARDANO_NETWORK ? +DEFAULT_NETWORK_ID! : undefined,
          hashTx: hashTx,
        }),
      );
    } catch (error) {
      console.error('[PORTFOLIO:WITHDRAW] Happen error: ', error);
      addNotification(
        getWithdrawNotificationOptions({
          id,
          status: 'error',
          content: `${withdrawingLiquidity} ${t('failed')}`,
          hideExplorerLink: true,
        }),
      );

      throw error;
    } finally {
      console.groupEnd();
    }
  }

  async function withdrawDelegateLocal(
    portfolio: Portfolio,
    withdrawTokens: { token: Token; amount: BigNumber }[],
    account: string,
  ): Promise<void> {
    console.groupCollapsed('[PORTFOLIO:WITHDRAW DELEGATE] withdrawDelegateLocal');

    const id = getUniqueId();
    const withdrawingLiquidity = `${t('withdrawingLiquidity')} ${t('from')} ${portfolio.name}`;

    try {
      addNotification(
        getWithdrawNotificationOptions({
          id,
          status: 'inProgress',
          content: withdrawingLiquidity,
          hideExplorerLink: true,
        }),
      );

      console.log(`waiting 'withdraw delegate' transaction`);
      const transactionResponse = await delegateWithdraw(
        withdrawTokens,
        easyModeForm.lpTokenAmount,
        account,
      );
      console.log(`has 'withdraw delegate' transaction : `, transactionResponse);

      await updateFarms();
      await updatePortfolios();

      const hashTx = transactionResponse.transactionHash;
      addNotification(
        getWithdrawNotificationOptions({
          id,
          status: 'success',
          content: `${withdrawingLiquidity} ${t('succeeded')}`,
          hideExplorerLink: false,
          explorerChainId: ENABLE_FAKE_CARDANO_NETWORK ? +DEFAULT_NETWORK_ID! : undefined,
          hashTx: hashTx,
        }),
      );
    } catch (error) {
      console.error('[PORTFOLIO:WITHDRAW DELEGATE] Happen error: ', error);
      addNotification(
        getWithdrawNotificationOptions({
          id,
          status: 'error',
          content: `${withdrawingLiquidity} ${t('failed')}`,
          hideExplorerLink: true,
        }),
      );

      throw error;
    } finally {
      console.groupEnd();
    }
  }

  async function withdrawCrossChain(
    portfolio: Portfolio,
    withdrawTokens: { token: Token; amount: BigNumber }[],
    account: string,
  ): Promise<void> {
    console.groupCollapsed('[PORTFOLIO:WITHDRAW:CROSS:CHAIN] addLiquidityCrossChain');

    const id = getUniqueId();
    const withdrawingLiquidity = `${t('withdrawingLiquidity')} ${t('from')} ${portfolio.name}`;

    const step1: INotificationStep = {
      current: 1,
      total: 2,
      chainId: portfolio.chainId,
    };
    const step2: INotificationStep = {
      current: 2,
      total: 2,
      chainId: easyModeForm.destinationChainId,
    };

    let step = { ...step1 };

    try {
      // step 1
      addNotification(
        getWithdrawNotificationOptions({
          id,
          status: 'inProgress',
          content: withdrawingLiquidity,
          step,
          hideExplorerLink: true,
        }),
      );

      console.log(`waiting 'withdraw' transaction`);
      const firstTx = await withdraw(withdrawTokens, easyModeForm.lpTokenAmount, account);
      console.log(`has 'withdraw' transaction : `, firstTx);
      const firstHashTx = firstTx.transactionHash;
      addNotification(
        getWithdrawNotificationOptions({
          id,
          status: 'success',
          content: `${withdrawingLiquidity} ${t('succeeded')}`,
          step,
          hideExplorerLink: false,
          hashTx: firstHashTx,
        }),
      );

      // step 2
      step = { ...step2 };
      addNotification(
        getWithdrawNotificationOptions({
          id,
          status: 'inProgress',
          content: withdrawingLiquidity,
          step,
          hideExplorerLink: true,
        }),
      );

      console.log(`waiting 'withdraw' cross-chain transaction`);
      const secondTx = await fetchSwapTransactionStatus(portfolio.chainId, firstTx.transactionHash);
      console.log(`has 'withdraw' cross-chain transaction : `, secondTx);

      await updatePortfolios();

      const secondHashTx = secondTx.transactionStatus.txHashDestination;
      addNotification(
        getWithdrawNotificationOptions({
          id,
          status: 'success',
          content: `${withdrawingLiquidity} ${t('succeeded')}`,
          step,
          hideExplorerLink: false,
          hashTx: secondHashTx,
        }),
      );
    } catch (error) {
      console.error('[PORTFOLIO:WITHDRAW:CROSS:CHAIN] Happen error: ', error);
      addNotification(
        getWithdrawNotificationOptions({
          id,
          status: 'error',
          content: `${withdrawingLiquidity} ${t('failed')}`,
          step,
          hideExplorerLink: true,
        }),
      );

      throw error;
    } finally {
      console.groupEnd();
    }
  }

  async function doWithdraw() {
    if (!withdrawForm.selectedTokens) return;

    let account = walletState.wallets[SELECTED_NETWORK_NAME].account;

    if (easyModeForm.portfolio.type === PortfolioSource.PORTFOLIO_CROSSCHAIN) {
      account = needDestinationAddress.value ? easyModeForm.destinationAddress : account;

      await withdrawCrossChain(
        easyModeForm.portfolio,
        withdrawForm.selectedTokens as { token: Token; amount: BigNumber }[],
        account,
      );
      return;
    }

    if (
      easyModeForm.useFarmsLp &&
      easyModeForm.lpTokenAmount.gt(easyModeForm.portfolio.getBalanceOfLp())
    ) {
      await withdrawDelegateLocal(
        easyModeForm.portfolio,
        withdrawForm.selectedTokens as { token: Token; amount: BigNumber }[],
        account,
      );
      return;
    }

    await withdrawLocal(
      easyModeForm.portfolio,
      withdrawForm.selectedTokens as { token: Token; amount: BigNumber }[],
      account,
    );
  }

  watch(
    () => easyModeForm.destinationAddress,
    destinationAddress => {
      setDestinationAddressWSC(destinationAddress);
    },
  );

  return {
    withdrawForm,
    setSelectedTokens,
    checkAllowance,
    setAllowance,
    doWithdraw,
    $reset,
  };
});
