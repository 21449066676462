import _ from 'lodash';
import { reactive } from 'vue';
import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { DEFAULT_NETWORK_ID, SELECTED_NETWORK_NAME } from '@/helpers/networkParams.helper';
import { getScanLink } from '@/sdk/utils';
import { getErc20Contract, transactionWithEstimatedGas } from '@/helpers/contract.helper';
import { Staking } from '@/sdk/entities/staking';
import { Token } from '@/sdk/entities/token';
import { IStakingStakeForm } from '@/store/modules/staking/models/staking-stake-form.interface';
import { checkERC20Allowance } from '@/helpers/token.helper';
import {
  INotification,
  NotificationStatus,
} from '@/store/modules/notifications/models/notification.interface';
import { StakingManualPoolTitle } from '@/store/modules/staking/models/staking-pool';
import { useNotifications } from '@/store/modules/notifications/useNotifications';
import { useWallet } from '@/store/modules/wallet/useWallet';
import { useManualStakingTransactions } from '@/composables/staking/manual/useManualStakingTransactions';

export const useManualStakingStake = defineStore('manualStakingStake', () => {
  const { t } = useI18n();
  const { walletState } = useWallet();
  const { stake } = useManualStakingTransactions();
  const { addNotification } = useNotifications();

  const stakingStakeForm = reactive<IStakingStakeForm>({
    pool: null,
    input: {
      token: null,
      amountIn: null, // In WEI
    },
    hasAllowance: false,
  });

  function $reset(): void {
    stakingStakeForm.hasAllowance = false;
    stakingStakeForm.pool = null;
    stakingStakeForm.input = {
      amountIn: null,
      token: null,
    };
  }

  function setStakeToPool(pool: Staking, token: Token, amountInWei: string): void {
    stakingStakeForm.pool = pool;
    stakingStakeForm.input = {
      amountIn: amountInWei,
      token,
    };
  }

  async function checkAllowance() {
    console.groupCollapsed('[MANUAL:POOL:STAKE] checkAllowance');
    if (!walletState.isInjected) return false;
    if (!stakingStakeForm.pool) return false;
    if (!stakingStakeForm.input.token) return false;

    const token = stakingStakeForm.input.token;

    const allowanceInWei = await checkERC20Allowance(
      token,
      walletState.wallets[SELECTED_NETWORK_NAME].account ?? '',
      stakingStakeForm.pool.farm,
    );

    stakingStakeForm.hasAllowance =
      !allowanceInWei.isZero() && allowanceInWei.gte(stakingStakeForm.input.amountIn ?? '0');

    console.log(`has approve ${token.symbol} for stake : `, stakingStakeForm.hasAllowance);
    console.groupEnd();
  }

  async function setAllowance() {
    console.groupCollapsed('[MANUAL:POOL:STAKE] setAllowance');
    if (!walletState.isInjected) return false;
    if (stakingStakeForm.hasAllowance) return false;
    if (!stakingStakeForm.pool) return false;
    if (!stakingStakeForm.input.token) return false;

    const token = stakingStakeForm.input.token;

    try {
      addNotification(getApproveNotificationOptions('inProgress'));

      const tokenContract = getErc20Contract(token.address, getInstance()?.web3.getSigner());

      const result = await transactionWithEstimatedGas(tokenContract, 'approve', [
        stakingStakeForm.pool.farm,
        stakingStakeForm.input.amountIn ?? '0',
      ]);

      await result.wait();

      const explorerChainId = ENABLE_FAKE_CARDANO_NETWORK ? +DEFAULT_NETWORK_ID! : undefined;
      addNotification(
        getApproveNotificationOptions(
          'success',
          getScanLink(result.hash, 'transaction', explorerChainId),
        ),
      );
    } catch (err) {
      console.error(
        `[MANUAL:POOL:STAKE:SET_APPROVE] Can not approve token [${token.symbol} | ${token.address}]. ERROR: `,
        err,
      );
      addNotification(getApproveNotificationOptions('error'));
      throw Error(err);
    } finally {
      console.groupEnd();
    }
  }

  async function doStake() {
    console.groupCollapsed('[MANUAL:POOL:STAKE] doStake');
    const cStakingStakeForm: IStakingStakeForm = _.cloneDeep(stakingStakeForm);

    if (!cStakingStakeForm.pool) return;
    if (!cStakingStakeForm.input.token) return;

    const token = cStakingStakeForm.input.token;
    const poolTitle = cStakingStakeForm.pool.isSyrupPool ? 'syrup' : 'blues';
    const notificationId = `manual_staking_stake_${poolTitle}_${token.address}`;

    try {
      addNotification(
        getManualStakeNotificationOptions(token, poolTitle, {
          id: notificationId,
          status: 'inProgress',
        }),
      );

      const transactionReceipt = await stake(
        cStakingStakeForm.pool,
        cStakingStakeForm.input.amountIn ?? '0',
      );

      const explorerChainId = ENABLE_FAKE_CARDANO_NETWORK ? +DEFAULT_NETWORK_ID! : undefined;
      addNotification(
        getManualStakeNotificationOptions(token, poolTitle, {
          id: notificationId,
          status: 'success',
          explorerLink: getScanLink(
            transactionReceipt.transactionHash,
            'transaction',
            explorerChainId,
          ),
        }),
      );
    } catch (error) {
      console.error(`[MANUAL:POOL:STAKE] Happen error during stake operation. ERROR : `, error);
      if (error.name === 'ProviderRpcError') {
        console.error(`[ERROR] ProviderRpcError. Error details : `, {
          code: error.code,
          data: error.data,
        });
      }

      addNotification(
        getManualStakeNotificationOptions(token, poolTitle, {
          id: notificationId,
          status: 'error',
        }),
      );

      throw Error(error);
    } finally {
      console.groupEnd();
    }
  }

  // NOTIFICATIONS

  function getApproveNotificationOptions(
    status: NotificationStatus,
    explorerLink?: string,
  ): INotification {
    const APPROVING_TOKENS = 'Approving tokens';
    return {
      id: `approve_manual`,
      status: status,
      content: APPROVING_TOKENS,
      life: status !== 'inProgress',
      explorerLink,
    };
  }

  function getManualStakeNotificationOptions(
    token: Token,
    poolTitle: StakingManualPoolTitle,
    options: {
      id: string;
      status: NotificationStatus;
      explorerLink?: string;
    },
  ): INotification {
    return {
      ...options,
      content: buildManualStakeNotificationContent(token, poolTitle, options.status),
    };
  }

  function buildManualStakeNotificationContent(
    token: Token,
    poolTitle: StakingManualPoolTitle,
    status: NotificationStatus,
  ): string {
    const poolTitleCaption = t(`yieldPool.manual.poolTitle.${poolTitle}.caption`, {
      name: token.name,
    });
    const stakingTokenToPool = `${t('staking')} ${token.symbol} ${t('to')} ${poolTitleCaption}`;

    const notifications: Record<NotificationStatus, string> = {
      inProgress: stakingTokenToPool,
      success: `${stakingTokenToPool} ${t('succeeded')}`,
      error: `${stakingTokenToPool} ${t('failed')}`,
    };

    return notifications[status];
  }
  // ====

  return {
    stakingStakeForm,
    setStakeToPool,
    checkAllowance,
    setAllowance,
    doStake,
    $reset,
  };
});
