import BigNumber from 'bignumber.js';
import mitt from 'mitt';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';
import { Portfolio, TokenInfo } from '@/sdk/entities/portfolio';
import { PortfolioSource } from '@/sdk/entities/PortfolioSource';
import {
  EasyModeAddLiquidityForm,
  EasyModeForm,
  EasyModeWithdrawForm,
} from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/models/easy-mode-form';
import {
  EasyModeAddLiquidityToken,
  EasyModeCommonToken,
  EasyModeToken,
  EasyModeWithdrawToken,
} from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/models/easy-mode-token';
import { BIG_ZERO } from '@/utils/bigNumber';
import { MIN_ALLOWED_WEIGHT } from '@/sdk/constants';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';
import { LOSS } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/models/LOSS';
import { getTokenBalance } from '@/composables/token/useTokenBalance';
import { compareTokenAddresses, fromWei, toWei } from '@/sdk/utils';
import { getLossType } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/loss';
import { howManyTokensCanBridgeFromCardano } from '@/helpers/milkomeda-wrapped-smartcontract/milkomeda-wsc-calculation';
import { useBalances } from '@/store/modules/tokens/useBalances';
import { usePortfoliosMilkomedaWSCBridge } from '@/store/modules/portfolios/usePortfoliosMilkomedaWSCBridge';

const REST_AMOUNT_THRESHOLD_IN_USD = 0.01;

const easyModeForm: EasyModeForm = reactive<EasyModeForm>(
  {} as unknown as EasyModeForm,
) as EasyModeForm;

const { milkomedaWSCBridgeState } = usePortfoliosMilkomedaWSCBridge();

const formEmitter = mitt<{ decreaseAmount: undefined }>();

interface InitEasyFormStruct {
  portfolio: Portfolio;
  action: 'addLiquidity' | 'withdraw';
  baseOrLpTokenAmount: BigNumber;
}

function initEasyModeForm({ portfolio, action, baseOrLpTokenAmount }: InitEasyFormStruct): void {
  const { state } = useStore();
  formEmitter.all.clear();
  easyModeForm.portfolio = portfolio;
  easyModeForm.sourceChainId = portfolio.chainId;
  easyModeForm.destinationChainId = portfolio.chainId;
  easyModeForm.destinationAddress = null;
  easyModeForm.action = action;
  if (easyModeForm.action === 'addLiquidity') {
    easyModeForm.withinBalances = true;
    easyModeForm.baseTokenAmount = baseOrLpTokenAmount;
  } else {
    easyModeForm.lpTokenAmount = baseOrLpTokenAmount;
  }
  easyModeForm.lpToken = portfolio.lpToken;

  easyModeForm.restAmountToDistributeAfterFirstIteration = BIG_ZERO;
  easyModeForm.selectedCurrencySymbol = '';
  easyModeForm.balanceError = false;
  easyModeForm.balanceOfSelectedTokensError = false;
  easyModeForm.lossPercent = undefined;
  easyModeForm.crossChainLossPercent = undefined;
  easyModeForm.impactError = undefined;
  easyModeForm.restAmount = undefined;
  easyModeForm.tokens = generateEasyModeTokens(portfolio, action);
  easyModeForm.useFarmsLp = canUseFarmLP(portfolio, state.farming.lp);
}

export function useEasyModeForm() {
  const lossUSD = computed(() => {
    if (!easyModeForm.lossPercent) {
      return easyModeForm.lossPercent;
    }

    let totalInUSD: BigNumber;
    if (easyModeForm.action === 'addLiquidity') {
      totalInUSD = easyModeForm.baseTokenAmount.multipliedBy(easyModeForm.portfolio.priceInUSD);
    } else {
      totalInUSD = easyModeForm.lpTokenAmount.multipliedBy(
        easyModeForm.portfolio.getLpTokenPriceUSD(),
      );
    }

    return totalInUSD.multipliedBy(easyModeForm.lossPercent).div(100);
  });

  const lossType = computed(() => {
    if (!easyModeForm.lossPercent || !lossUSD.value) {
      return undefined;
    }

    return getLossType(lossUSD.value, easyModeForm.lossPercent);
  });

  const crossChainLossUSD = computed(() => {
    if (!easyModeForm.crossChainLossPercent) {
      return easyModeForm.crossChainLossPercent;
    }

    let totalInUSD: BigNumber;
    if (easyModeForm.action === 'addLiquidity') {
      totalInUSD = easyModeForm.baseTokenAmount.multipliedBy(easyModeForm.portfolio.priceInUSD);
    } else {
      totalInUSD = easyModeForm.lpTokenAmount.multipliedBy(
        easyModeForm.portfolio.getLpTokenPriceUSD(),
      );
    }

    return totalInUSD.multipliedBy(easyModeForm.crossChainLossPercent).div(100);
  });

  const restAmountThresholdInBase = computed(() => {
    // USD / (USD / BASE) => BASE
    return BigNumber(REST_AMOUNT_THRESHOLD_IN_USD).div(easyModeForm.portfolio.priceInUSD);
  });

  const isValidRestAmount = computed(() => {
    const restAmountInBase = fromWei(
      easyModeForm.restAmount ?? BIG_ZERO,
      easyModeForm.portfolio.baseToken.decimals,
    );
    return restAmountInBase.lte(restAmountThresholdInBase.value);
  });

  const needDestinationAddress = computed(() => {
    return ENABLE_FAKE_CARDANO_NETWORK && easyModeForm.destinationChainId !== +DEFAULT_NETWORK_ID!;
  });

  return {
    easyModeForm,
    forceDisabled: computed(() => {
      if (easyModeForm.action === 'addLiquidity') {
        return !easyModeForm.baseTokenAmount.gt(0);
      } else {
        return !easyModeForm.lpTokenAmount.gt(0);
      }
    }),
    baseOrLpTokenAmountWei: computed(() => {
      let amount: BigNumber;
      let decimals: number;

      if (easyModeForm.action === 'addLiquidity') {
        amount = easyModeForm.baseTokenAmount;
        decimals = easyModeForm.portfolio.baseToken.decimals;
      } else {
        amount = easyModeForm.lpTokenAmount;
        // NOTE: Changed `LP_TOKEN_DECIMALS` -> easyModeForm.portfolio.lpToken.decimals
        decimals = easyModeForm.portfolio.lpToken.decimals;
      }

      return toWei(amount, decimals);
    }),
    selectedTokens: computed(() =>
      (easyModeForm.tokens as EasyModeCommonToken[]).filter(formToken => formToken.checked),
    ),
    lossIsLoading: computed(() => easyModeForm.lossPercent === null),
    lossUSD,
    lossType,
    lossIsHuge: computed(() => lossType.value === LOSS.HUGE),
    lossIsSmall: computed(() => lossType.value === LOSS.SMALL),
    crossChainLossUSD,
    isValidRestAmount,
    needDestinationAddress,
    actionButtonDisabled: computed(() => {
      const loadingCompleted = easyModeForm.lossPercent != null && easyModeForm.impactError != null;
      const hasDestinationAddress = needDestinationAddress.value
        ? !!easyModeForm.destinationAddress
        : true;
      const invalidADABalanceIntoCardano =
        ENABLE_FAKE_CARDANO_NETWORK &&
        milkomedaWSCBridgeState.needBridge &&
        !milkomedaWSCBridgeState.isValidCardanoBalance;
      const hasMoreThanZeroAmount = (easyModeForm.tokens as EasyModeCommonToken[])
        .filter(formToken => formToken.checked)
        .filter(formToken => (formToken as EasyModeToken).amount?.gt(0));
      const buttonEnabled =
        loadingCompleted &&
        !easyModeForm.impactError &&
        /*lossType.value !== LOSS.HUGE &&*/ // TODO временно отключили пока не готов Expert mode
        isValidRestAmount.value &&
        !easyModeForm.balanceError &&
        !easyModeForm.balanceOfSelectedTokensError &&
        hasDestinationAddress &&
        !invalidADABalanceIntoCardano &&
        hasMoreThanZeroAmount.length > 0;

      console.groupCollapsed('[ADD LIQUIDITY / WITHDRAW] button enabled : ', buttonEnabled);
      console.log('loadingCompleted : ', loadingCompleted);
      console.log('not impactError : ', !easyModeForm.impactError);
      console.log('not rest amount : ', !easyModeForm.restAmount?.gt(0));
      console.log('is valid rest amount : ', isValidRestAmount.value);
      console.log('not balance error : ', !easyModeForm.balanceError);
      console.log(
        'not balance of selected tokens error : ',
        !easyModeForm.balanceOfSelectedTokensError,
      );
      if (needDestinationAddress.value) {
        console.log('destination address is not empty : ', hasDestinationAddress);
      }
      console.groupEnd();

      return !buttonEnabled;
    }),
    initEasyModeForm,
    getPortfolioAvailableTokens,
    getTokenInfoByFormToken,
    disableTokensWithZeroBalance,
    formEmitter,
    updateTokens: () => {
      easyModeForm.tokens = generateEasyModeTokens(easyModeForm.portfolio, easyModeForm.action);
    },
    calculateHowManyTokensCanBridgeFromCardano,
  };
}

function generateEasyModeTokens<T extends 'addLiquidity' | 'withdraw'>(
  portfolio: Portfolio,
  mode: T,
): T extends 'addLiquidity' ? EasyModeAddLiquidityToken[] : EasyModeWithdrawToken[] {
  const availableTokens = getPortfolioAvailableTokens(portfolio);

  const formTokens = availableTokens.map(token => {
    const easyModeToken: EasyModeToken = {
      address: token.tokenAddress,
      disabled: false,
      checked: false,
      draggable: true,
      amount: BIG_ZERO,
      noLossLimit: BIG_ZERO,
      notice: undefined,
    };

    return mode === 'addLiquidity'
      ? {
          ...easyModeToken,
        }
      : { ...easyModeToken, noLossLimit: BIG_ZERO, lpWeiToWithdraw: BIG_ZERO };
  }) as T extends 'addLiquidity' ? EasyModeAddLiquidityToken[] : EasyModeWithdrawToken[];

  const sortedFormTokens = sortTokensByExcessLimits(formTokens, mode);

  if (mode === 'addLiquidity') {
    checkOrDisableZeroBalanceTokens(sortedFormTokens as EasyModeAddLiquidityToken[]);
  } else {
    disableZeroReserveTokens(sortedFormTokens as EasyModeWithdrawToken[]);
  }

  return sortedFormTokens;
}

function checkOrDisableZeroBalanceTokens(formTokens: EasyModeAddLiquidityToken[]): void {
  const { balances: allBalances } = useBalances();

  const tokensInfo: TokenInfo[] = formTokens.map(getTokenInfoByFormToken);

  const balances: BigNumber[] = tokensInfo.map(
    tokenInfo =>
      new BigNumber(
        allBalances?.[tokenInfo.token.unwrapWETH().symbol!]?.balance.raw.toString() || '0',
      ),
  );

  balances.forEach((balance, index) => {
    const formToken = formTokens[index];

    if (balance.gt(0) && !formToken.disabled) {
      formToken.checked = true;
    } else if ((easyModeForm as EasyModeAddLiquidityForm).withinBalances) {
      formToken.disabled = true;
    }
  });
}

function disableZeroReserveTokens(formTokens: EasyModeWithdrawToken[]): void {
  formTokens.forEach(formToken => {
    const tokenInfo = getTokenInfoByFormToken(formToken);

    if (tokenInfo.baseTokenAmountEquivalent.eq(0)) {
      formToken.disabled = true;
    }
  });
}

function sortTokensByExcessLimits<
  T extends EasyModeAddLiquidityForm['action'] | EasyModeWithdrawForm['action'],
>(
  formTokens: T extends EasyModeAddLiquidityForm['action']
    ? EasyModeAddLiquidityToken[]
    : EasyModeWithdrawToken[],
  action: EasyModeAddLiquidityForm['action'] | EasyModeWithdrawForm['action'],
): T extends EasyModeAddLiquidityForm['action']
  ? EasyModeAddLiquidityToken[]
  : EasyModeWithdrawToken[] {
  const availableTokens = getPortfolioAvailableTokens(easyModeForm.portfolio);
  const sortParameter = calculateExcessLimits(availableTokens);

  const tokensWithProfit = availableTokens.map((tokenInfo, index) => ({
    address: tokenInfo.token.address,
    profitable: sortParameter[index],
  }));

  const desc = action === 'addLiquidity' ? -1 : 1;

  const tokensSortedByProfit = tokensWithProfit.sort((a, b) =>
    b.profitable.minus(a.profitable).multipliedBy(desc).toNumber(),
  );

  return tokensSortedByProfit.map(
    item =>
      (formTokens as EasyModeToken[]).find(token =>
        compareTokenAddresses(item.address, token.address),
      )!,
  ) as T extends EasyModeAddLiquidityForm['action']
    ? EasyModeAddLiquidityToken[]
    : EasyModeWithdrawToken[];
}

function getTokenInfoByFormToken(formToken: EasyModeToken): TokenInfo {
  return getPortfolioAvailableTokens(easyModeForm.portfolio).find(tokenInfo =>
    compareTokenAddresses(tokenInfo.tokenAddress, formToken.address),
  )!;
}

function calculateHowManyTokensCanBridgeFromCardano() {
  const availableTokens = getPortfolioAvailableTokens(easyModeForm.portfolio).map(
    tokenInfo => tokenInfo.token,
  );
  return howManyTokensCanBridgeFromCardano(availableTokens);
}

function disableTokensWithZeroBalance() {
  easyModeForm.tokens.forEach(formToken => {
    const tokenInfo = getTokenInfoByFormToken(formToken);
    const balance = getTokenBalance(tokenInfo.token, {
      subTxFeeFromNativeBalance: true,
      countHowManyCanBridgeFromCardano: calculateHowManyTokensCanBridgeFromCardano(),
    });

    if (balance.eq(0)) {
      formToken.checked = false;
      formToken.disabled = true;
    }
  });
}

function isPortfolioBigShareBaseToken(portfolio: Portfolio) {
  return BigNumber(100)
    .minus(
      portfolio.baseTokenInfo
        .getPortfolioSharePercent(portfolio.portfolioTotalValueBase)
        .toFixed(4),
    )
    .div(portfolio.baseTokenInfo.targetWeight.multipliedBy(100).toFixed(4))
    .lt(MIN_ALLOWED_WEIGHT);
}

function isPortfolioSmallShareToken(portfolio: Portfolio, tokenInfo: TokenInfo) {
  return BigNumber(tokenInfo.getPortfolioSharePercent(portfolio.portfolioTotalValueBase).toFixed(4))
    .div(tokenInfo.targetWeight.multipliedBy(100).toFixed(4))
    .lt(MIN_ALLOWED_WEIGHT);
}

export function getPortfolioBlockedTokens(portfolio: Portfolio) {
  if (easyModeForm.action === 'addLiquidity') {
    return portfolio.tokens.filter(
      tokenInfo =>
        compareTokenAddresses(tokenInfo.tokenAddress, portfolio.baseTokenAddress) &&
        isPortfolioBigShareBaseToken(portfolio),
    );
  } else {
    return portfolio.tokens.filter(tokenInfo => isPortfolioSmallShareToken(portfolio, tokenInfo));
  }
}

function getPortfolioAvailableTokens(portfolio: Portfolio) {
  const portfolioTokens = portfolio.tokens;
  const blockedTokens = getPortfolioBlockedTokens(portfolio);

  if (easyModeForm.action === 'addLiquidity') {
    return portfolioTokens.filter(
      tokenInfo =>
        !blockedTokens.some(blockedTokenInfo =>
          compareTokenAddresses(tokenInfo.tokenAddress, blockedTokenInfo.tokenAddress),
        ),
    );
  }

  // withdraw
  const portfolioTokensAvailableInDestinationChain =
    easyModeForm.portfolio.type === PortfolioSource.PORTFOLIO_CROSSCHAIN
      ? portfolioTokens.filter(tokenInfo =>
          tokenInfo.availableOn.includes(easyModeForm.destinationChainId.toString()),
        )
      : portfolioTokens;

  const crossChainTokensAvailableInDestinationChain = portfolio.crossChainTokens.filter(tokenInfo =>
    tokenInfo.availableOn.includes(easyModeForm.destinationChainId.toString()),
  );

  return [
    ...portfolioTokensAvailableInDestinationChain,
    ...crossChainTokensAvailableInDestinationChain,
  ].filter(
    tokenInfo =>
      !blockedTokens.some(blockedTokenInfo =>
        compareTokenAddresses(tokenInfo.tokenAddress, blockedTokenInfo.tokenAddress),
      ),
  );
}

/**
 * returns [W[]]
 * Ui = Ri - V * Wi
 */
function calculateExcessLimits(tokens: TokenInfo[]) {
  const portfolio = easyModeForm.portfolio;
  const portfolioValueWei = portfolio.totalValueWithProtocolFee;

  return tokens.map(tokenInfo =>
    tokenInfo.baseTokenAmountEquivalent.minus(
      portfolioValueWei.multipliedBy(tokenInfo.targetWeight),
    ),
  );
}

export function canUseFarmLP(portfolio: Portfolio, listOfExistingLP: Record<string, any>): boolean {
  const hasFarm = !!listOfExistingLP[portfolio.lpTokenAddress];

  return (
    portfolio.type === PortfolioSource.PORTFOLIO_LOCALE && hasFarm && !ENABLE_FAKE_CARDANO_NETWORK
  );
}
