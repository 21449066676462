import _ from 'lodash';
import { reactive } from 'vue';
import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { DEFAULT_NETWORK_ID, SELECTED_NETWORK_NAME } from '@/helpers/networkParams.helper';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { fromWei, getScanLink } from '@/sdk/utils';
import { Token } from '@/sdk/entities/token';
import { getErc20Contract, transactionWithEstimatedGas } from '@/helpers/contract.helper';
import { useNotifications } from '@/store/modules/notifications/useNotifications';
import {
  INotification,
  NotificationStatus,
} from '@/store/modules/notifications/models/notification.interface';
import { useWallet } from '@/store/modules/wallet/useWallet';
import { BridgeToken } from '@/composables/milkomeda-wrapped-smartcontract/models/bridge-token';
import { useFarmingMilkomedaWSCBridge } from '@/store/modules/farming/useFarmingMilkomedaWSCBridge';
import { useFarmingTransactions } from '@/composables/farming/useFarmingTransactions';
import { IFarmingStakeForm } from '../models/farming-stake-form.interface';
import { PortfolioFarm } from '@/sdk/entities/portfolioFarm';
import { checkERC20Allowance } from '@/helpers/token.helper';

export const useFarmingStake = defineStore('farmingStake', () => {
  const { t } = useI18n();
  const { walletState } = useWallet();
  const {
    setBridgeTokensFromCardano,
    setIsEVMFromCardano,
    setHasBridgeFromMilkomeda,
    $reset: resetMilkomedaWSCBridge,
  } = useFarmingMilkomedaWSCBridge();
  const { stake } = useFarmingTransactions();
  const { addNotification } = useNotifications();

  const farmingStakeForm = reactive<IFarmingStakeForm>({
    farm: null,
    amountIn: null, // In WEI
    hasAllowance: false,
  });

  function $reset(): void {
    farmingStakeForm.hasAllowance = false;
    farmingStakeForm.farm = null;
    farmingStakeForm.amountIn = null;
    resetMilkomedaWSCBridge();
  }

  function checkStakeForBridgeFromCardano(amountInWei: string, token: Token) {
    if (!ENABLE_FAKE_CARDANO_NETWORK) return;

    resetMilkomedaWSCBridge();
    setIsEVMFromCardano(false);
    setHasBridgeFromMilkomeda(false);
    const bridgeTokens: BridgeToken[] = [
      {
        amount: fromWei(amountInWei, token.decimals).toString(),
        token,
      },
    ];

    setBridgeTokensFromCardano(bridgeTokens);
  }

  function setStakeToFarm(farm: PortfolioFarm, amountInWei: string): void {
    farmingStakeForm.farm = farm;
    farmingStakeForm.amountIn = amountInWei;
  }

  async function checkAllowance() {
    console.groupCollapsed('[FARMING:STAKE] checkAllowance');
    if (!walletState.isInjected) return false;
    if (!farmingStakeForm.farm) return false;

    const lpToken = farmingStakeForm.farm.liquidityToken;

    const allowanceInWei = await checkERC20Allowance(
      lpToken,
      walletState.wallets[SELECTED_NETWORK_NAME].account ?? '',
      farmingStakeForm.farm.farm,
    );

    farmingStakeForm.hasAllowance =
      !allowanceInWei.isZero() && allowanceInWei.gte(farmingStakeForm.amountIn ?? '0');

    console.log('has approve LP for stake : ', farmingStakeForm.hasAllowance);
    console.groupEnd();
  }

  async function setAllowance() {
    console.groupCollapsed('[FARMING:STAKE] setAllowance');
    if (!walletState.isInjected) return false;
    if (farmingStakeForm.hasAllowance) return false;
    if (!farmingStakeForm.farm) return false;

    const lpToken = farmingStakeForm.farm.liquidityToken;

    try {
      addNotification(getApproveNotificationOptions('inProgress'));

      const tokenContract = getErc20Contract(lpToken.address, getInstance()?.web3.getSigner());

      const result = await transactionWithEstimatedGas(tokenContract, 'approve', [
        farmingStakeForm.farm.farm,
        farmingStakeForm.amountIn,
      ]);

      await result.wait();

      const explorerChainId = ENABLE_FAKE_CARDANO_NETWORK ? +DEFAULT_NETWORK_ID! : undefined;
      addNotification(
        getApproveNotificationOptions(
          'success',
          getScanLink(result.hash, 'transaction', explorerChainId),
        ),
      );
    } catch (err) {
      console.error(
        `[FARMING:STAKE:SET_APPROVE] Can not approve token [${lpToken.symbol} | ${lpToken.address}]. ERROR: `,
        err,
      );
      addNotification(getApproveNotificationOptions('error'));
      throw Error(err);
    } finally {
      console.groupEnd();
    }
  }

  async function doStake() {
    console.groupCollapsed('[FARMING:STAKE] doStake');
    const cFarmingStakeForm: IFarmingStakeForm = _.cloneDeep(farmingStakeForm);

    if (!cFarmingStakeForm.farm) return;
    if (!cFarmingStakeForm.amountIn) return;

    const portfolioOfFarm = cFarmingStakeForm.farm.portfolio;
    const notificationId = `farming_stake_${portfolioOfFarm.contractAddress}`;

    try {
      addNotification(
        getStakeNotificationOptions(portfolioOfFarm.name, {
          id: notificationId,
          status: 'inProgress',
        }),
      );

      const transactionReceipt = await stake(
        cFarmingStakeForm.farm as PortfolioFarm,
        cFarmingStakeForm.amountIn,
      );

      const explorerChainId = ENABLE_FAKE_CARDANO_NETWORK ? +DEFAULT_NETWORK_ID! : undefined;
      addNotification(
        getStakeNotificationOptions(portfolioOfFarm.name, {
          id: notificationId,
          status: 'success',
          explorerLink: getScanLink(
            transactionReceipt.transactionHash,
            'transaction',
            explorerChainId,
          ),
        }),
      );
    } catch (error) {
      console.error(`[FARMING:STAKE] Happen error during stake operation. ERROR : `, error);
      if (error.name === 'ProviderRpcError') {
        console.error(`[ERROR] ProviderRpcError. Error details : `, {
          code: error.code,
          data: error.data,
        });
      }

      addNotification(
        getStakeNotificationOptions(portfolioOfFarm.name, {
          id: notificationId,
          status: 'error',
        }),
      );

      throw error;
    } finally {
      console.groupEnd();
    }
  }

  // NOTIFICATIONS

  function getApproveNotificationOptions(
    status: NotificationStatus,
    explorerLink?: string,
  ): INotification {
    const APPROVING_TOKENS = 'Approving tokens';
    return {
      id: `approve_${farmingStakeForm.farm?.farm}`,
      status: status,
      content: APPROVING_TOKENS,
      life: status !== 'inProgress',
      explorerLink,
    };
  }

  function getStakeNotificationOptions(
    portfolioName: string,
    options: {
      status: NotificationStatus;
      id: string;
      explorerLink?: string;
    },
  ): INotification {
    const content = buildStakeNotificationContent(portfolioName, options.status);

    return {
      ...options,
      content,
    };
  }

  function buildStakeNotificationContent(portfolioName: string, status: NotificationStatus) {
    const content = `${t('staking')} ${t('lpTokens')} ${t('to')} ${portfolioName} ${t('farm')}`;

    const notifications: Record<NotificationStatus, string> = {
      inProgress: content,
      success: `${content} ${t('succeeded')}`,
      error: `${content} ${t('failed')}`,
    };

    return notifications[status];
  }
  // ====

  return {
    farmingStakeForm,
    checkStakeForBridgeFromCardano,
    setStakeToFarm,
    checkAllowance,
    setAllowance,
    doStake,
    $reset,
  };
});
