<template>
  <div class="panel-stake">
    <div class="flex flex-column sm:flex-row justify-content-between">
      <div class="flex sm:flex-order-2 sm:ml-auto align-items-center mb-s3 sm:mb-s0">
        <Button
          :label="$t('yieldPool.stake')"
          class="w-6 button-base mr-s1"
          :class="{ active: tabActive === 'stake' }"
          @click="setTabActive('stake')"
        />
        <Button
          :label="$t('yieldPool.unstake')"
          class="w-6 button-base ml-s1"
          :class="{ active: tabActive === 'unstake' }"
          @click="setTabActive('unstake')"
        />
      </div>

      <div class="sm:flex gutter-x-s6 gutter-y-s3">
        <div>
          <Caption
            :caption="$t('yieldPool.auto.totalCompound.caption')"
            :tooltip="$t('yieldPool.auto.totalCompound.tooltip')"
            class="mb-s1"
          />
          <div
            class="flex gutter-s1"
            :class="
              $store.state.layout.isTablet ? 'flex-column align-items-start' : 'align-items-center'
            "
          >
            <div>{{ format(pool.printTotalCompound) }} BLUES</div>
            <div :class="$store.state.layout.isTablet ? 'text-muted text-sm' : 'text-muted'">
              <span v-if="!$store.state.layout.isTablet">/</span>
              {{ printUSD(pool.printTotalCompoundUSD) }}
            </div>
          </div>
        </div>
        <div>
          <Caption
            :caption="$t('yieldPool.auto.availableCompound.caption')"
            :tooltip="$t('yieldPool.auto.availableCompound.tooltip')"
            class="mb-s1"
          />
          <div
            class="flex gutter-s1"
            :class="
              $store.state.layout.isTablet ? 'flex-column align-items-start' : 'align-items-center'
            "
          >
            <div>{{ format(pool.printAvailableCompound) }} BLUES</div>
            <div :class="$store.state.layout.isTablet ? 'text-muted text-sm' : 'text-muted'">
              <span v-if="!$store.state.layout.isTablet">/</span>
              {{ printUSD(pool.printAvailableCompoundUSD) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-column md:flex-row">
      <div class="w-full flex-grow-1 mt-s3">
        <InputPanel
          :action="tabActive"
          :pool="pool"
          pool-type="auto"
          :isMaxUnstakeMode="isMaxUnstakeMode"
          :setMaxUnstakeMode="onSetMaxUnstakeMode"
          @hasValidationError="
            this.disableButton = $event.hasError || inProgress;
            this.inputValue = $event.value;
          "
          v-model="inputValue"
          modelValue="inputValue"
          :disabled="isDisabledInput"
        />
      </div>

      <div
        class="flex w-full sm:w-18rem sm:flex-shrink-0 mt-s3 sm:pl-s3 flex-column justify-content-between"
      >
        <UnstackingFeeTimer
          v-if="checkTimer && tabActive === 'unstake' && pool.printAvailableCompound.gt(0)"
          :time="pool.userTimeFeeEnd.toNumber()"
          :percent="pool.withdrawFee.toFixed()"
          class="mx-auto mt-s4 sm:mt-s0"
          @endTimer="isTimer = $event"
        />
        <FilledListItem
          v-else
          :caption="$t('inUsd')"
          :data="`${printUSD(pool.calculateUSD(this.inputValue ?? 0))}`"
        />
        <Button
          class="w-full button-primary mt-s3 button-lg"
          @click="onDialogOpen(tabActive)"
          :disabled="disableButton || isDisabledInput || isNotEnoughADA || isNetworkDisabledToAdd"
        >
          <Spinner color="white" size="24" class="mr-s2" v-if="inProgress" />
          {{ inProgress ? $t('inProgress') : $t(`yieldPool.${tabActive}Btn`) }}
        </Button>
      </div>
    </div>

    <Dialog
      v-model:visible="isShowDialog"
      :header="getConfirmDialogHeader()"
      dismissableMask
      :modal="true"
      :close-on-escape="closeOnEscape"
      :content-class="$store.state.layout.isMobile ? 'scroll-container' : ''"
    >
      <component
        :is="tabActive === 'stake' ? 'StakeConfirmation' : 'UnstakeConfirmation'"
        :in-progress="inProgress"
        :amount="this.inputValue"
        :amount-usd="pool.calculateUSD(this.inputValue)"
        :token="pool.token"
        :cancel="onDialogClose"
        :confirm="onConfirm"
        :checkTimer="checkTimer"
        :pool="pool"
      >
      </component>
    </Dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { toWei } from '@/sdk/utils';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';
import { MODULE_NAMES } from '@/store';
import { AUTO_STAKING_ACTION_TYPES } from '@/store/modules/staking/autoStaking.module';
import { UPDATE_INTERVAL } from '@/helpers/constants';
import { STEP_STATUS } from '@/components/stepper/stepper.types';
import InputPanel from '@/views/pages/investment/staking/pool/stake/InputPanel';
import FilledListItem from '@/components/filledList/FilledListItem';
import StakeConfirmation from './confirmation/StakeConfirmation';
import UnstakeConfirmation from './confirmation/UnstakeConfirmation';
import { format, printUSD } from '@/utils/format';
import Caption from '@/components/Caption';
import Spinner from '@/components/Spinner';
import UnstackingFeeTimer from './unstackingFeeTimer/UnstackingFeeTimer';
import { isNestedDialogOpen } from '@/composables/utils/useUtils';
import { useWallet } from '@/store/modules/wallet/useWallet';
import { useAllSteps } from '@/composables/useAllSteps';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { useBalances } from '@/store/modules/tokens/useBalances';
import { useStakingSteps } from '@/store/modules/staking/useStakingSteps';
import { useStakingStakeSteps } from '@/store/modules/staking/stake/useStakingStakeSteps';
import { useStakingStake } from '@/store/modules/staking/stake/useStakingStake';
import { useStakingUnstakeSteps } from '@/store/modules/staking/unstake/useStakingUnstakeSteps';
import { useStakingUnstake } from '@/store/modules/staking/unstake/useStakingUnstake';
import { checkIfNetworkIdDisabledToAdd } from '@/helpers/network-disable.helper';

const utilsMixin = {
  methods: {
    format,
    printUSD,
  },
};

export default {
  name: 'Stake',
  mixins: [utilsMixin],
  components: {
    UnstackingFeeTimer,
    Caption,
    InputPanel,
    FilledListItem,
    StakeConfirmation,
    UnstakeConfirmation,
    Spinner,
  },
  props: {
    pool: Object,
    wscAdaValidator: Object,
  },
  emits: ['openDocs', 'sendTransaction'],
  setup() {
    const { walletState } = useWallet();
    const allStepsState = useAllSteps();
    const { getTokenByAddressAndChainId } = useTokens();
    const { updateTokenBalances } = useBalances();
    const { stakingSteps, setStaking, $reset: resetStakingSteps } = useStakingSteps();
    const { stakingStakeSteps } = useStakingStakeSteps();
    const {
      stakingStake,
      checkStakeForBridgeFromCardano,
      setStakeToPool,
      checkAllowance,
      setAllowance,
      doStake,
      $reset: resetStakingStake,
    } = useStakingStake();
    const { stakingUnstakeSteps } = useStakingUnstakeSteps();
    const {
      stakingUnstake,
      checkUnstakeForBridgeFromMilkomeda,
      setUnstakeFromPool,
      doUnstake,
      $reset: resetStakingUnstake,
    } = useStakingUnstake();

    return {
      // wallet
      walletState,
      // all steps
      allStepsState,
      // tokens
      getTokenByAddressAndChainId,
      // balance
      updateTokenBalances,
      // staking steps
      stakingSteps,
      setStaking,
      resetStakingSteps,
      // stake steps
      stakingStakeSteps,
      // staking stake
      stakingStake,
      checkStakeForBridgeFromCardano,
      setStakeToPool,
      checkAllowance,
      setAllowance,
      doStake,
      resetStakingStake,
      // unstake steps
      stakingUnstakeSteps,
      // staking unstake
      stakingUnstake,
      checkUnstakeForBridgeFromMilkomeda,
      setUnstakeFromPool,
      doUnstake,
      resetStakingUnstake,
    };
  },
  data() {
    return {
      tabActive: 'stake',
      isShowDialog: false,
      inputValue: '',
      disableButton: true,
      inProgress: false,
      isTimer: true,
      isMaxUnstakeMode: false,
      stakingPool: 'auto',
    };
  },
  async created() {
    try {
      this.updateInterval = setInterval(async () => {
        await this.getStatusAutoStaking();
        await this.updateAutoStakingInfo();
        await this.updateAutoStakingPools();
      }, UPDATE_INTERVAL);
    } catch (e) {
      console.debug(e);
    }
  },
  watch: {
    inputValue: {
      handler(amount) {
        this.checkBalanceADAIntoCardano();

        if (!amount) {
          this.resetStakingStake();
          this.resetStakingUnstake();
          return;
        }
      },
    },
    isSuccessfulStakeSteps: {
      handler(isSuccessful) {
        if (isSuccessful) {
          this.resetForm();
        }
      },
    },
    isSuccessfulUnstakeSteps: {
      handler(isSuccessful) {
        if (isSuccessful) {
          this.resetForm();
        }
      },
    },
  },
  methods: {
    ...mapActions(MODULE_NAMES.AUTO_STAKING, {
      getStatusAutoStaking: AUTO_STAKING_ACTION_TYPES.GET_STATUS_AUTO_STAKING,
      updateAutoStakingInfo: AUTO_STAKING_ACTION_TYPES.UPDATE_INFO,
      updateAutoStakingPools: AUTO_STAKING_ACTION_TYPES.UPDATE_AUTO_STAKING,
    }),
    resetForm() {
      this.inputValue = '';
      this.inProgress = false;
      this.disableButton = true;
    },
    checkBalanceADAIntoCardano() {
      if (!this.walletState.isInjected) return;

      if (this.isStakeTabActive && !this.inputValue) {
        this.wscAdaValidator.$reset();

        return;
      }

      if (this.isStakeTabActive) {
        const { amount: amountInWei, token } = this.amountToken;
        this.wscAdaValidator.checkBalanceADAForBridgeFromCardano(amountInWei, token);

        return;
      }

      if (this.isUnstakeTabActive) {
        const amountInWei = toWei(this.unstakeBalance, this.poolToken.decimals).toString();
        this.wscAdaValidator.checkBalanceADAForBridgeFromMilkomeda(amountInWei, this.poolToken);

        return;
      }
    },
    setTabActive(tab) {
      this.tabActive = tab;
      this.checkBalanceADAIntoCardano();
    },
    onDialogOpen() {
      this.isShowDialog = true;
    },
    onDialogClose() {
      this.isShowDialog = false;
    },
    getConfirmDialogHeader() {
      const poolTitle = this.$t(`yieldPool.auto.poolTitle.caption`);
      return this.$t(`yieldPool.${this.tabActive}ConfirmDialog.header`, { poolTitle: poolTitle });
    },
    onSetMaxUnstakeMode(mode) {
      this.isMaxUnstakeMode = mode;
    },
    async onConfirmWSCAction() {
      if (this.stakingSteps.isShown) {
        this.resetStakingSteps();
      }

      this.setStaking(this.stakingPool, this.tabActive);
      const amountInInWei = toWei(this.inputValue ?? '0', this.poolToken.decimals).toString();

      if (this.isStakeTabActive) {
        this.checkStakeForBridgeFromCardano(amountInInWei, this.poolToken);
        this.setStakeToPool(this.stakingPool, this.pool, this.poolToken, amountInInWei);
        await this.checkAllowance();
      } else if (this.isUnstakeTabActive) {
        this.checkUnstakeForBridgeFromMilkomeda(amountInInWei, this.poolToken);
        this.setUnstakeFromPool(this.stakingPool, this.pool, this.poolToken, amountInInWei, {
          mode: this.isMaxUnstakeMode ? 'ALL' : 'AMOUNT',
        });
        this.isMaxUnstakeMode = false;
      }

      await this.$nextTick();

      this.$emit('sendTransaction');
    },
    async onConfirmAction() {
      this.inProgress = true;
      this.disableButton = true;

      const amountInInWei = toWei(this.inputValue ?? '0', this.poolToken.decimals).toString();

      try {
        if (this.isStakeTabActive) {
          this.setStakeToPool(this.stakingPool, this.pool, this.poolToken, amountInInWei);

          await this.checkAllowance();
          if (!this.stakingStake.stakingStakeForm.hasAllowance) {
            await this.setAllowance();
          }
          await this.doStake();
        } else if (this.isUnstakeTabActive) {
          this.setUnstakeFromPool(this.stakingPool, this.pool, this.poolToken, amountInInWei, {
            mode: this.isMaxUnstakeMode ? 'ALL' : 'AMOUNT',
          });
          this.isMaxUnstakeMode = false;
          await this.doUnstake();
        }
      } finally {
        this.resetForm();
      }
    },
    async onConfirm() {
      this.onDialogClose();

      if (ENABLE_FAKE_CARDANO_NETWORK) {
        await this.onConfirmWSCAction();
        return;
      }

      await this.onConfirmAction();
    },
  },
  computed: {
    checkTimer() {
      const currentTime = new Date().getTime();
      return currentTime < this.pool.userTimeFeeEnd.toNumber() * 1000;
    },
    closeOnEscape() {
      return !isNestedDialogOpen.value;
    },
    isSomeInProgress() {
      return this.inProgress || this.allStepsState.isInProgress.state;
    },
    poolToken() {
      if (!DEFAULT_NETWORK_ID) return;

      return this.getTokenByAddressAndChainId(this.pool.token, +DEFAULT_NETWORK_ID);
    },
    amountToken() {
      return {
        amount: toWei(this.inputValue ?? '0', this.poolToken?.decimals).toString(),
        token: this.poolToken,
      };
    },
    isNotEnoughADA() {
      return !this.wscAdaValidator.milkomedaWSCBalanceADAIntoCardanoValidatorState
        .isValidCardanoBalance;
    },
    isStakeTabActive() {
      return this.tabActive === 'stake';
    },
    isUnstakeTabActive() {
      return this.tabActive === 'unstake';
    },
    isSuccessfulStakeSteps() {
      if (!ENABLE_FAKE_CARDANO_NETWORK) return false;
      if (!this.stakingStakeSteps.steps.length) return false;
      if (!this.stakingStake.stakingStakeForm) return false;
      if (!this.stakingStake.stakingStakeForm.pool) return false;
      if (this.stakingSteps.action !== 'stake') return false;

      return (
        !this.stakingSteps.inProgress &&
        this.stakingStake.stakingStakeForm.pool.farm === this.pool.farm &&
        this.stakingStakeSteps.steps.at(-1).status === STEP_STATUS.SUCCESS
      );
    },
    isSuccessfulUnstakeSteps() {
      if (!ENABLE_FAKE_CARDANO_NETWORK) return false;
      if (!this.stakingUnstakeSteps.steps.length) return false;
      if (!this.stakingUnstake.stakingUnstakeForm) return false;
      if (!this.stakingUnstake.stakingUnstakeForm.pool) return false;
      if (this.stakingSteps.action !== 'unstake') return false;

      return (
        !this.stakingSteps.inProgress &&
        this.stakingUnstake.stakingUnstakeForm.pool.farm === this.pool.farm &&
        this.stakingUnstakeSteps.steps.at(-1).status === STEP_STATUS.SUCCESS
      );
    },
    isDisabledInput() {
      return !this.walletState.isInjected || this.isSomeInProgress;
    },
    isNetworkDisabledToAdd() {
      return checkIfNetworkIdDisabledToAdd(DEFAULT_NETWORK_ID) && this.isStakeTabActive;
    },
  },
};
</script>

<style scoped></style>
