<template>
  <div class="panel-stake">
    <div class="flex flex-column sm:flex-row justify-content-between">
      <div class="flex sm:flex-order-2 sm:ml-auto align-items-center mb-s3 sm:mb-s0">
        <Button
          :label="t('yieldPool.stake')"
          class="w-6 button-base mr-s1"
          :class="{ active: tabActive === 'stake' }"
          @click="setTabActive('stake')"
        />
        <Button
          :label="t('yieldPool.unstake')"
          class="w-6 button-base ml-s1"
          :class="{ active: tabActive === 'unstake' }"
          @click="setTabActive('unstake')"
        />
      </div>

      <div>
        <Caption :caption="t('yieldPool.manual.youStaked.caption')" class="mb-s1" />
        <div class="flex flex-column sm:flex-row align-items-start sm:align-items-center">
          <div class="mt-s1 mr-s2">
            {{ format(pool.printYouStaked) }} {{ poolToken?.symbol }}
            <span class="text-muted ml-s1">/ {{ printUSD(pool.printYouStakedUSD) }}</span>
          </div>
          <Button
            class="button-text mt-s1"
            :label="t('getTokens', { token: poolToken?.symbol })"
            @click="
              $router.push(swapWithTokenSymbolsRoute);
              $emit('click');
            "
          />
        </div>
      </div>
    </div>
    <div class="flex flex-column md:flex-row">
      <div class="w-full md:w-8 mt-s3">
        <InputPanel
          :action="tabActive"
          :pool="pool"
          pool-type="manual"
          @hasValidationError="onChangedInput($event)"
          v-model="inputValue"
          modelValue="inputValue"
          :disabled="isDisabledInput"
        />
      </div>

      <div class="w-full sm:w-4 mt-s3 sm:pl-s3 align-self-center">
        <FilledListItem
          :caption="t('inUsd')"
          :data="`${printUSD(pool.calculateUSD(this.inputValue ?? 0))}`"
        />
        <Button
          class="w-full button-primary mt-s3 button-lg"
          @click="onDialogOpen(tabActive)"
          :disabled="disableButton || isDisabledInput || isNotEnoughADA || isNetworkDisabledToAdd"
        >
          <Spinner color="white" size="24" class="mr-s2" v-if="inProgress" />
          {{
            inProgress
              ? t('inProgress')
              : t(`yieldPool.${tabActive}Btn`, { token: poolToken.symbol })
          }}
        </Button>
      </div>
    </div>

    <Dialog
      v-model:visible="isShowDialog"
      :header="getConfirmDialogHeader()"
      dismissableMask
      :modal="true"
      :content-class="$store.state.layout.isMobile ? 'scroll-container' : ''"
    >
      <Confirmation
        :in-progress="inProgress"
        :amount="this.inputValue"
        :amount-usd="pool.calculateUSD(this.inputValue).toString()"
        :token="poolToken"
        :cancel="onDialogClose"
        :confirm="onConfirm"
        :action="tabActive"
      >
      </Confirmation>
    </Dialog>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import { computed } from 'vue';
import { mapActions } from 'vuex';
import { toWei } from '@/sdk/utils';
import { MODULE_NAMES } from '@/store';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';
import { NETWORK_ETH_TOKEN_NAME } from '@/helpers/network-eth-token';
import { UPDATE_INTERVAL } from '@/helpers/constants';
import { STAKING_ACTION_TYPES } from '@/store/modules/staking/staking.module';
import { STEP_STATUS } from '@/components/stepper/stepper.types';
import InputPanel from './InputPanel';
import FilledListItem from '@/components/filledList/FilledListItem';
import Confirmation from './confirmation/Confirmation';
import { buildSwapWithTokenSymbolsRoute } from '@/helpers/route.helper';
import { format, printUSD } from '@/utils/format';
import Caption from '@/components/Caption';
import Spinner from '@/components/Spinner';
import { useAllSteps } from '@/composables/useAllSteps';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { useBalances } from '@/store/modules/tokens/useBalances';
import { useWallet } from '@/store/modules/wallet/useWallet';
import { useStakingSteps } from '@/store/modules/staking/useStakingSteps';
import { useStakingStakeSteps } from '@/store/modules/staking/stake/useStakingStakeSteps';
import { useStakingStake } from '@/store/modules/staking/stake/useStakingStake';
import { useStakingUnstakeSteps } from '@/store/modules/staking/unstake/useStakingUnstakeSteps';
import { useStakingUnstake } from '@/store/modules/staking/unstake/useStakingUnstake';
import { Staking } from '@/sdk/entities/staking';
import { useI18n } from 'vue-i18n';
import { checkIfNetworkIdDisabledToAdd } from '@/helpers/network-disable.helper';

const utilsMixins = {
  methods: {
    format,
    printUSD,
  },
};

export default {
  name: 'Stake',
  mixins: [utilsMixins],
  components: { Caption, InputPanel, FilledListItem, Confirmation, Spinner },
  props: {
    pool: Staking,
    wscAdaValidator: Object,
  },
  setup(props) {
    const { walletState } = useWallet();
    const allStepsState = useAllSteps();
    const { getTokenByAddressAndChainId } = useTokens();
    const { updateTokenBalances } = useBalances();
    const { stakingSteps, setStaking, $reset: resetStakingSteps } = useStakingSteps();
    const { stakingStakeSteps } = useStakingStakeSteps();
    const {
      stakingStake,
      checkStakeForBridgeFromCardano,
      setStakeToPool,
      checkAllowance,
      setAllowance,
      doStake,
      $reset: resetStakingStake,
    } = useStakingStake();
    const { stakingUnstakeSteps } = useStakingUnstakeSteps();
    const {
      stakingUnstake,
      checkUnstakeForBridgeFromMilkomeda,
      setUnstakeFromPool,
      doUnstake,
      $reset: resetStakingUnstake,
    } = useStakingUnstake();
    const { t } = useI18n();

    const poolToken = computed(() => {
      if (!DEFAULT_NETWORK_ID) return;
      if (props.pool.token === ethers.constants.AddressZero) return null;

      return getTokenByAddressAndChainId(props.pool.token, +DEFAULT_NETWORK_ID);
    });

    return {
      // wallet
      walletState,
      // all steps
      allStepsState,
      // tokens
      getTokenByAddressAndChainId,
      // balance
      updateTokenBalances,
      // staking steps
      stakingSteps,
      setStaking,
      resetStakingSteps,
      // stake steps
      stakingStakeSteps,
      // staking stake
      stakingStake,
      checkStakeForBridgeFromCardano,
      setStakeToPool,
      checkAllowance,
      setAllowance,
      doStake,
      resetStakingStake,
      // unstake steps
      stakingUnstakeSteps,
      // staking unstake
      stakingUnstake,
      checkUnstakeForBridgeFromMilkomeda,
      setUnstakeFromPool,
      doUnstake,
      resetStakingUnstake,
      t,
      poolToken,
    };
  },
  data() {
    return {
      tabActive: 'stake',
      isShowDialog: false,
      inputValue: '',
      disableButton: true,
      inProgress: false,
      isTimer: true,
    };
  },
  async created() {
    try {
      this.updateInterval = setInterval(async () => {
        await this.getStatusStaking();
        await this.updateStakingPools();
      }, UPDATE_INTERVAL);
    } catch (e) {
      console.debug(e);
    }
  },
  watch: {
    inputValue: {
      handler(amount) {
        if (!this.poolToken) {
          this.resetStakingStake();
          this.resetStakingUnstake();
          return;
        }

        this.checkBalanceADAIntoCardano();

        if (!amount) {
          this.resetStakingStake();
          this.resetStakingUnstake();
          return;
        }
      },
    },
    isSuccessfulStakeSteps: {
      handler(isSuccessful) {
        if (isSuccessful) {
          this.resetForm();
        }
      },
    },
    isSuccessfulUnstakeSteps: {
      handler(isSuccessful) {
        if (isSuccessful) {
          this.resetForm();
        }
      },
    },
  },
  methods: {
    ...mapActions(MODULE_NAMES.STAKING, {
      getStatusStaking: STAKING_ACTION_TYPES.GET_STATUS_STAKING,
      updateStakingPools: STAKING_ACTION_TYPES.UPDATE_STAKING_POOLS,
    }),
    resetForm() {
      this.inputValue = '';
      this.inProgress = false;
      this.disableButton = true;
    },
    checkBalanceADAIntoCardano() {
      if (!this.poolToken || !this.walletState.isInjected) return;

      if (this.isStakeTabActive) {
        if (!this.inputValue) {
          this.wscAdaValidator.$reset();
          return;
        }

        const { amount: amountInWei, token } = this.amountToken;
        this.wscAdaValidator.checkBalanceADAForBridgeFromCardano(amountInWei, token);

        return;
      }

      if (this.isUnstakeTabActive) {
        const amountInWei = toWei(this.unstakeBalance, this.poolToken.decimals).toString();
        this.wscAdaValidator.checkBalanceADAForBridgeFromMilkomeda(amountInWei, this.poolToken);

        return;
      }
    },
    setTabActive(tab) {
      this.tabActive = tab;
      this.checkBalanceADAIntoCardano();
    },
    onDialogOpen() {
      this.isShowDialog = true;
    },
    onDialogClose() {
      this.isShowDialog = false;
    },
    getConfirmDialogHeader() {
      const poolTitle = this.$t(
        `yieldPool.manual.poolTitle.${this.pool.isSyrupPool ? 'syrup' : 'blues'}.caption`,
        { name: this.poolToken.name },
      );
      return this.$t(`yieldPool.${this.tabActive}ConfirmDialog.header`, { poolTitle: poolTitle });
    },
    onChangedInput(event) {
      this.disableButton = event.hasError || this.inProgress;
      this.inputValue = event.value;
    },
    // STAKE / UNSTAKE
    async onConfirmWSCAction() {
      if (this.stakingSteps.isShown) {
        this.resetStakingSteps();
      }

      this.setStaking(this.stakingPool, this.tabActive);
      const amountInInWei = toWei(this.inputValue ?? '0', this.poolToken.decimals).toString();

      if (this.isStakeTabActive) {
        this.checkStakeForBridgeFromCardano(amountInInWei, this.poolToken);
        this.setStakeToPool(this.stakingPool, this.pool, this.poolToken, amountInInWei);
        await this.checkAllowance();
      } else if (this.isUnstakeTabActive) {
        this.checkUnstakeForBridgeFromMilkomeda(amountInInWei, this.poolToken);
        this.setUnstakeFromPool(this.stakingPool, this.pool, this.poolToken, amountInInWei);
      }

      await this.$nextTick();

      this.$emit('sendTransaction');
    },
    async onConfirmAction() {
      this.inProgress = true;
      this.disableButton = true;

      const amountInInWei = toWei(this.inputValue ?? '0', this.poolToken.decimals).toString();

      try {
        if (this.isStakeTabActive) {
          this.setStakeToPool(this.stakingPool, this.pool, this.poolToken, amountInInWei);

          await this.checkAllowance();
          if (!this.stakingStake.stakingStakeForm.hasAllowance) {
            await this.setAllowance();
          }
          await this.doStake();
        } else if (this.isUnstakeTabActive) {
          this.setUnstakeFromPool(this.stakingPool, this.pool, this.poolToken, amountInInWei);
          await this.doUnstake();
        }
      } finally {
        this.resetForm();
      }
    },
    async onConfirm() {
      this.onDialogClose();

      if (ENABLE_FAKE_CARDANO_NETWORK) {
        await this.onConfirmWSCAction();
        return;
      }

      await this.onConfirmAction();
    },
  },
  computed: {
    swapWithTokenSymbolsRoute() {
      return buildSwapWithTokenSymbolsRoute(this.selectedNetCurrency, this.poolToken?.symbol);
    },
    unstakeBalance() {
      return this.pool.printYouStaked.toFixed();
    },
    checkTimer() {
      const currentTime = new Date().getTime();
      return currentTime < this.pool.userTimeFeeEnd.toNumber() * 1000;
    },
    selectedNetCurrency() {
      return NETWORK_ETH_TOKEN_NAME;
    },
    stakingPool() {
      return this.autoStaking ? 'auto' : 'manual';
    },
    isSomeInProgress() {
      return this.inProgress || this.allStepsState.isInProgress.state;
    },
    amountToken() {
      return {
        amount: toWei(this.inputValue ?? '0', this.poolToken?.decimals).toString(),
        token: this.poolToken,
      };
    },
    isNotEnoughADA() {
      return !this.wscAdaValidator.milkomedaWSCBalanceADAIntoCardanoValidatorState
        .isValidCardanoBalance;
    },
    isStakeTabActive() {
      return this.tabActive === 'stake';
    },
    isUnstakeTabActive() {
      return this.tabActive === 'unstake';
    },
    isSuccessfulStakeSteps() {
      if (!ENABLE_FAKE_CARDANO_NETWORK) return false;
      if (!this.stakingStakeSteps.steps.length) return false;
      if (!this.stakingStake.stakingStakeForm) return false;
      if (!this.stakingStake.stakingStakeForm.pool) return false;
      if (this.stakingSteps.action !== 'stake') return false;

      return (
        !this.stakingSteps.inProgress &&
        this.stakingStake.stakingStakeForm.pool.farm === this.pool.farm &&
        this.stakingStakeSteps.steps.at(-1).status === STEP_STATUS.SUCCESS
      );
    },
    isSuccessfulUnstakeSteps() {
      if (!ENABLE_FAKE_CARDANO_NETWORK) return false;
      if (!this.stakingUnstakeSteps.steps.length) return false;
      if (!this.stakingUnstake.stakingUnstakeForm) return false;
      if (!this.stakingUnstake.stakingUnstakeForm.pool) return false;
      if (this.stakingSteps.action !== 'unstake') return false;

      return (
        !this.stakingSteps.inProgress &&
        this.stakingUnstake.stakingUnstakeForm.pool.farm === this.pool.farm &&
        this.stakingUnstakeSteps.steps.at(-1).status === STEP_STATUS.SUCCESS
      );
    },
    isDisabledInput() {
      return !this.walletState.isInjected || this.isSomeInProgress;
    },
    isNetworkDisabledToAdd() {
      return checkIfNetworkIdDisabledToAdd(DEFAULT_NETWORK_ID) && this.isStakeTabActive;
    },
  },
};
</script>

<style scoped></style>
