import { safeParseUnits } from '@/helpers/utils';
import { ethers } from 'ethers';
import { ISwapForm } from './models/swap-form.interface';
import BigNumber from 'bignumber.js';

type AmountsEstimationResult = {
  amounts: BigNumber[];
  fees: number[];
};

export async function getAmountsOutWithPortfolios(
  swapForm: ISwapForm,
  routerContract: ethers.Contract,
): Promise<AmountsEstimationResult> {
  console.groupCollapsed('[SWAP:STABLESWAP:ESTIMATION] getAmountsOutWithPortfolios');

  const amountIn = safeParseUnits(swapForm.input.amount, swapForm.input.token?.decimals).toString();
  const path = swapForm.bestTrade.route.path;
  const portfolios = swapForm.bestTrade.route.portfolios;
  const destinationChain =
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1 ? 0 : swapForm.output.token?.chainId;
  const firstXChainPortfolioNum =
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
      ? 0
      : swapForm.bestTrade.route.crossChainPortfolioIndex;

  const params = [amountIn, path, portfolios, destinationChain, firstXChainPortfolioNum];

  console.log('params: ', params);
  console.groupEnd();

  return routerContract.getAmountsOutWithPortfolios(...params);
}

export async function getAmountsInWithPortfolios(
  swapForm: ISwapForm,
  routerContract: ethers.Contract,
): Promise<AmountsEstimationResult> {
  console.groupCollapsed('[SWAP:STABLESWAP:ESTIMATION] getAmountsOutWithPortfolios');

  const amountOut = safeParseUnits(
    swapForm.output.amount,
    swapForm.output.token?.decimals,
  ).toString();
  const path = swapForm.bestTrade.route.path;
  const portfolios = swapForm.bestTrade.route.portfolios;
  const destinationChain =
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1 ? 0 : swapForm.input.token?.chainId;
  const firstXChainPortfolioNum =
    +swapForm.bestTrade.route.crossChainPortfolioIndex === -1
      ? 0
      : swapForm.bestTrade.route.crossChainPortfolioIndex;

  const params = [amountOut, path, portfolios, destinationChain, firstXChainPortfolioNum];

  console.log('params: ', params);
  console.groupEnd();

  return routerContract.getAmountsInWithPortfolios(...params);
}
