import _ from 'lodash';
import { defineStore } from 'pinia';
import { computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';
import { DEFAULT_CARDANO_CHAIN_ID } from '@/constants/DEFAULT_CARDANO_ID';
import { fromWei, getScanLink } from '@/sdk/utils';
import { calculateGasForReversBridge } from '@/helpers/milkomeda-wrapped-smartcontract/milkomeda-wsc-calculation';
import {
  INotification,
  NotificationStatus,
} from '@/store/modules/notifications/models/notification.interface';
import { IFarmingUnstakeForm } from '@/store/modules/farming/models/farming-unstake-form.interface';
import { PortfolioFarm } from '@/sdk/entities/portfolioFarm';
import { Token } from '@/sdk/entities/token';
import { BridgeToken } from '@/composables/milkomeda-wrapped-smartcontract/models/bridge-token';
import { useNotifications } from '@/store/modules/notifications/useNotifications';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { useFarmingMilkomedaWSCBridge } from '@/store/modules/farming/useFarmingMilkomedaWSCBridge';
import { useFarmingMilkomedaWSCUnwrapBridge } from '@/store/modules/farming/useFarmingMilkomedaWSCUnwrapBridge';
import { useFarmingTransactions } from '@/composables/farming/useFarmingTransactions';

export const useFarmingUnstake = defineStore('farmingUnstake', () => {
  const { t } = useI18n();
  const { getTokenBySymbolAndChainId, isPresentTokenIntoNetwork } = useTokens();
  const {
    setBridgeTokensFromCardano,
    setIsEVMFromCardano,
    setHasBridgeFromMilkomeda,
    $reset: resetMilkomedaWSCBridge,
  } = useFarmingMilkomedaWSCBridge();
  const {
    setBridgeTokensFromMilkomeda,
    setIsEVMFromMilkomeda,
    $reset: resetMilkomedaWSCUnwrapBridge,
  } = useFarmingMilkomedaWSCUnwrapBridge();
  const { unstake } = useFarmingTransactions();
  const { addNotification } = useNotifications();

  const farmingUnstakeForm = reactive<IFarmingUnstakeForm>({
    farm: null,
    amountIn: null, // In WEI
  });

  const cardanoADATokenForBridge = computed<Token>(() => {
    return getTokenBySymbolAndChainId('ADA', +DEFAULT_CARDANO_CHAIN_ID);
  });

  function $reset(): void {
    farmingUnstakeForm.farm = null;
    farmingUnstakeForm.amountIn = null;
  }

  function checkUnstakeForBridgeFromMilkomeda(amountInWei: string, token: Token) {
    if (!ENABLE_FAKE_CARDANO_NETWORK) return;
    resetMilkomedaWSCBridge();
    resetMilkomedaWSCUnwrapBridge();

    const bridgeTokens: BridgeToken[] = [];
    if (isPresentTokenIntoNetwork(token.symbol!, +DEFAULT_CARDANO_CHAIN_ID)) {
      bridgeTokens.push({
        amount: fromWei(amountInWei, token.decimals).toString(),
        token: token,
      });
    }

    setIsEVMFromCardano(false);
    setHasBridgeFromMilkomeda(!!bridgeTokens.length);

    if (bridgeTokens.length) {
      setIsEVMFromMilkomeda(false);
      setBridgeTokensFromMilkomeda(bridgeTokens);
    } else {
      resetMilkomedaWSCUnwrapBridge();
    }

    const gas = calculateGasForReversBridge(cardanoADATokenForBridge.value, bridgeTokens);
    setBridgeTokensFromCardano(
      gas ? [gas] : [{ amount: '0', token: cardanoADATokenForBridge.value }],
    );
  }

  function setUnstakeFromFarm(farm: PortfolioFarm, amountInWei: string): void {
    farmingUnstakeForm.farm = farm;
    farmingUnstakeForm.amountIn = amountInWei;
  }

  async function doUnstake() {
    console.groupCollapsed('[FARMING:UNSTAKE] doUnstake');
    const cfarmingUnstakeForm: IFarmingUnstakeForm = _.cloneDeep(farmingUnstakeForm);

    if (!cfarmingUnstakeForm.farm) return;
    if (!cfarmingUnstakeForm.amountIn) return;

    const portfolioOfFarm = cfarmingUnstakeForm.farm.portfolio;
    const notificationId = `farming_unstake_${portfolioOfFarm.contractAddress}`;

    try {
      addNotification(
        getUnstakeNotificationOptions(portfolioOfFarm.name, {
          id: notificationId,
          status: 'inProgress',
        }),
      );

      const transactionReceipt = await unstake(
        cfarmingUnstakeForm.farm,
        cfarmingUnstakeForm.amountIn,
      );

      const explorerChainId = ENABLE_FAKE_CARDANO_NETWORK ? +DEFAULT_NETWORK_ID! : undefined;
      addNotification(
        getUnstakeNotificationOptions(portfolioOfFarm.name, {
          id: notificationId,
          status: 'success',
          explorerLink: getScanLink(
            transactionReceipt.transactionHash,
            'transaction',
            explorerChainId,
          ),
        }),
      );
    } catch (error) {
      console.error(`[FARMING:UNSTAKE] Happen error during unstake operation. ERROR : `, error);
      if (error.name === 'ProviderRpcError') {
        console.error(`[ERROR] ProviderRpcError. Error details : `, {
          code: error.code,
          data: error.data,
        });
      }

      addNotification(
        getUnstakeNotificationOptions(portfolioOfFarm.name, {
          id: notificationId,
          status: 'error',
        }),
      );

      throw error;
    } finally {
      console.groupEnd();
    }
  }

  // NOTIFICATIONS

  function getUnstakeNotificationOptions(
    portfolioName: string,
    options: {
      status: NotificationStatus;
      id: string;
      explorerLink?: string;
    },
  ): INotification {
    const content = buildUnstakeNotificationContent(portfolioName, options.status);

    return {
      ...options,
      content,
    };
  }

  function buildUnstakeNotificationContent(portfolioName: string, status: NotificationStatus) {
    const content = `${t('unstaking')} ${t('lpTokens')} ${t('from')} ${portfolioName} ${t('farm')}`;

    const notifications: Record<NotificationStatus, string> = {
      inProgress: content,
      success: `${content} ${t('succeeded')}`,
      error: `${content} ${t('failed')}`,
    };

    return notifications[status];
  }
  // ====

  return {
    farmingUnstakeForm,
    checkUnstakeForBridgeFromMilkomeda,
    setUnstakeFromFarm,
    doUnstake,
    $reset,
  };
});
