import { computed, reactive, watch } from 'vue';
import { defineStore } from 'pinia';
import { ethers } from 'ethers';
import { useI18n } from 'vue-i18n';
import { SelectedPortfolioTokens } from '@/store/modules/portfolios/models/selected-portfolio-tokens.interface';
import { useNotifications } from '@/store/modules/notifications/useNotifications';
import { Portfolio } from '@/sdk/entities/portfolio';
import { EasyModeAddLiquidityForm } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/models/easy-mode-form';
import { useEasyModeTransactions } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/composables/useEasyModeTransactions';
import { fetchSwapTransactionStatus } from '@/helpers/cross-chain-api';
import { useWallet } from '@/store/modules/wallet/useWallet';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { useEasyModeForm } from '../../../../../views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/composables/useEasyModeForm';
import BigNumber from 'bignumber.js';
import { Token } from '@/sdk/entities/token';
import { getDepositMethodParameters } from '@/helpers/portfolioDeposit.helper';
import { BIG_ZERO } from '@/utils/bigNumber';
import { checkERC20Allowance } from '@/helpers/token.helper';
import { getRouterAddress } from '@/helpers/address.helper';
import { fromWei, getScanLink } from '@/sdk/utils';
import { getErc20Contract, transactionWithEstimatedGas } from '@/helpers/contract.helper';
import { PortfolioSource } from '@/sdk/entities/PortfolioSource';
import {
  INotification,
  INotificationStep,
  NotificationStatus,
} from '@/store/modules/notifications/models/notification.interface';
import { IAddLiquidityForm } from './models/add-liquidity-form.interface';
import { BridgeAsset } from '@/store/modules/portfolios/easy-mode/models/bridge-asset';
import { usePortfoliosMilkomedaWSCBridge } from '@/store/modules/portfolios/usePortfoliosMilkomedaWSCBridge';
import { BridgeToken } from '@/composables/milkomeda-wrapped-smartcontract/models/bridge-token';
import { DEFAULT_NETWORK_ID, SELECTED_NETWORK_NAME } from '@/helpers/networkParams.helper';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { ChainId } from '@/sdk/constants';
import { getUniqueId } from '@/composables/utils/useUtils';
import { useFarmingTransactions } from '@/composables/farming/useFarmingTransactions';
import { useSpicePoints } from '@/composables/spice-points/useSpicePoints';

export const useEasyModeAddLiquidity = defineStore('easyModeAddLiquidity', () => {
  const { t } = useI18n();
  const { walletState } = useWallet();
  const { easyModeForm, needDestinationAddress } = useEasyModeForm();
  const {
    setBridgeTokensFromCardano,
    setDestinationAddress: setDestinationAddressWSC,
    setIsEVMFromCardano,
    setHasBridgeFromMilkomeda,
    $reset: resetMilkomedaWSCBridge,
  } = usePortfoliosMilkomedaWSCBridge();
  const { addLiquidity, updatePortfolios } = useEasyModeTransactions();
  const { updateFarms } = useFarmingTransactions();
  const { updateSpicePointsBalance } = useSpicePoints();
  const { addNotification } = useNotifications();

  const addLiquidityForm = reactive<IAddLiquidityForm>({
    selectedTokens: null,
    tokens: [],
    amountsIn: [], // In WEI
    approve: null,
  });

  function $reset(): void {
    addLiquidityForm.approve = null;
    addLiquidityForm.selectedTokens = null;
    addLiquidityForm.tokens = [];
    addLiquidityForm.amountsIn = [];
    resetMilkomedaWSCBridge();
  }

  const getApproveNotificationOptions = (
    status: NotificationStatus,
    explorerLink?: string,
  ): INotification => {
    const APPROVING_TOKENS = 'Approving tokens';
    return {
      id: `approve_${easyModeForm.portfolio.name}`,
      status: status,
      content: APPROVING_TOKENS,
      explorerLink,
    };
  };

  const getAddLiquidityNotificationOptions = (options: {
    status: NotificationStatus;
    id: string;
    content: string;
    step?: INotificationStep;
    hideExplorerLink?: boolean;
    explorerChainId?: ChainId;
    hashTx?: string;
  }): INotification => {
    const showLink = options.id && !options.hideExplorerLink;
    const explorerChainId = options.explorerChainId ?? options.step?.chainId;
    return {
      ...options,
      explorerLink:
        showLink && options.hashTx
          ? getScanLink(options.hashTx, 'transaction', explorerChainId)
          : undefined,
    };
  };

  const needApproveTokens = computed<boolean>(() => {
    return !!addLiquidityForm.approve?.length;
  });

  function setSelectedTokens(selectedTokens: SelectedPortfolioTokens): void {
    resetMilkomedaWSCBridge();
    if (!Object.keys(selectedTokens).length) return;

    setIsEVMFromCardano(needDestinationAddress.value);
    // NOTE: currently we do not unwrap LP tokens
    setHasBridgeFromMilkomeda(false);
    const { tokens, amountsIn } = getDepositMethodParameters(
      easyModeForm.portfolio,
      selectedTokens,
    );

    addLiquidityForm.selectedTokens = selectedTokens;
    addLiquidityForm.tokens = tokens;
    addLiquidityForm.amountsIn = amountsIn;

    const bridgeTokens: BridgeToken[] = tokens.map((token, index) => {
      return {
        amount: fromWei(amountsIn[index], token.decimals).toString(),
        token: token,
      };
    });

    setBridgeTokensFromCardano(bridgeTokens);
  }

  async function checkTokenAllowance(token: Token, amountInWei = BIG_ZERO) {
    let needApprove = true;

    if (token.isBaseToken() || token.isWETHToken()) {
      needApprove = false;
      return needApprove;
    }

    try {
      const allowanceInWei = await checkERC20Allowance(
        token,
        walletState.wallets[SELECTED_NETWORK_NAME].account ?? '',
        getRouterAddress(),
      );
      needApprove = allowanceInWei.isZero() || allowanceInWei.lt(amountInWei);
    } catch (err) {
      console.error(`[PORTFOLIO:ADD_LIQUIDITY:CHECK_ALLOWANCE] ERROR : `, err);
      needApprove = true;
    }

    return needApprove;
  }

  async function checkAllowance() {
    console.groupCollapsed('[PORTFOLIOS:ADD_LIQUIDITY] checkAllowance');
    if (!walletState.isInjected) return false;
    if (!addLiquidityForm.tokens.length) return false;

    const tokens = addLiquidityForm.tokens;
    const amountsIn = addLiquidityForm.amountsIn;
    const approve: BridgeAsset[] = [];

    const needApproveFlags = await Promise.all(
      addLiquidityForm.tokens.map((token, index) =>
        checkTokenAllowance(token, BigNumber(amountsIn[index])),
      ),
    );

    for (let i = 0; i < needApproveFlags.length; i++) {
      if (needApproveFlags[i]) {
        approve.push({ token: tokens[i], amount: amountsIn[i] });
      }
    }
    addLiquidityForm.approve = approve;

    console.log('need approve tokens : ', addLiquidityForm.approve);

    console.groupEnd();
  }

  async function setTokenAllowance(
    token: Token,
    amount: string,
  ): Promise<ethers.providers.TransactionResponse | undefined> {
    try {
      if (token.unwrapWETH().isETHToken()) {
        return;
      }

      const tokenContract = getErc20Contract(token.address, getInstance()?.web3.getSigner());

      const result = await transactionWithEstimatedGas(tokenContract, 'approve', [
        getRouterAddress(),
        amount,
      ]);

      await result.wait();

      return result;
    } catch (error) {
      console.error(
        `[PPRTFOLIO:SET_APPROVE] Can not approve token [${token.symbol} | ${token.address}]. ERROR: `,
        error,
      );
      throw error;
    }
  }

  async function setAllowance() {
    console.log('[PORTFOLIO:ADD:LIQUIDITY] setAllowance');
    if (!walletState.isInjected) return false;
    if (!addLiquidityForm.approve) return false;

    try {
      addNotification(getApproveNotificationOptions('inProgress'));

      const approveResponses: Array<ethers.providers.TransactionResponse | undefined> = [];
      for (let i = 0; i < addLiquidityForm.approve.length; i++) {
        const { token, amount } = addLiquidityForm.approve[i];
        approveResponses.push(await setTokenAllowance(token, amount));
      }

      let explorerLink: string | undefined;
      // NOTE: Check that approve one token
      if (approveResponses.length === 1 && approveResponses[0]) {
        explorerLink = getScanLink(
          approveResponses[0].hash,
          'transaction',
          ENABLE_FAKE_CARDANO_NETWORK ? +DEFAULT_NETWORK_ID! : undefined,
        );
      }

      addNotification(getApproveNotificationOptions('success', explorerLink));
    } catch (err) {
      addNotification(getApproveNotificationOptions('error'));
      throw Error(err);
    }
  }

  async function addLiquidityLocal(
    portfolio: Portfolio,
    portfolioForm: SelectedPortfolioTokens,
    account: string,
  ) {
    console.groupCollapsed('[PORTFOLIO:ADD:LIQUIDITY] addLiquidityLocal');

    const id = getUniqueId();
    const addingLiquidityContent = `
          ${t('addingLiquidity')} ${t('to')} ${portfolio.name}
        `;

    try {
      addNotification(
        getAddLiquidityNotificationOptions({
          id,
          status: 'inProgress',
          content: addingLiquidityContent,
          hideExplorerLink: true,
        }),
      );

      console.log(`waiting 'addLiquidity' transaction`);
      const transactionResponse = await addLiquidity(portfolioForm, account);
      console.log(`has 'addLiquidity' transaction : `, transactionResponse);

      if (easyModeForm.useFarmsLp) {
        await updateFarms();
      }
      await updatePortfolios();
      await updateSpicePointsBalance();

      const hashTx = transactionResponse.transactionHash;
      addNotification(
        getAddLiquidityNotificationOptions({
          id,
          status: 'success',
          content: `${addingLiquidityContent} ${t('succeeded')}`,
          hideExplorerLink: false,
          explorerChainId: ENABLE_FAKE_CARDANO_NETWORK ? +DEFAULT_NETWORK_ID! : undefined,
          hashTx: hashTx,
        }),
      );
    } catch (error) {
      console.error('[PORTFOLIO:ADD:LIQUIDITY] Happen error: ', error);
      addNotification(
        getAddLiquidityNotificationOptions({
          id,
          status: 'error',
          content: `${addingLiquidityContent} ${t('failed')}`,
          hideExplorerLink: true,
        }),
      );

      throw error;
    } finally {
      console.groupEnd();
    }
  }

  async function addLiquidityCrossChain(
    easyModeForm: EasyModeAddLiquidityForm,
    portfolio: Portfolio,
    portfolioForm: SelectedPortfolioTokens,
    account: string,
  ) {
    console.groupCollapsed('[PORTFOLIO:ADD:LIQUIDITY:CROSS:CHAIN] addLiquidityCrossChain');

    const id = getUniqueId();
    const addingLiquidityContent = `
          ${t('addingLiquidity')} ${t('to')} ${portfolio.name}
        `;
    const step1: INotificationStep = {
      current: 1,
      total: 2,
      chainId: portfolio.chainId,
    };
    const step2: INotificationStep = {
      current: 2,
      total: 2,
      chainId: easyModeForm.destinationChainId,
    };

    let step = { ...step1 };

    try {
      // step 1
      addNotification(
        getAddLiquidityNotificationOptions({
          id,
          status: 'inProgress',
          content: addingLiquidityContent,
          step,
          hideExplorerLink: true,
        }),
      );

      console.log(`waiting 'addLiquidity' transaction`);
      const firstTx = await addLiquidity(portfolioForm, account);
      console.log(`has 'addLiquidity' transaction : `, firstTx);
      const firstHashTx = firstTx.transactionHash;

      addNotification(
        getAddLiquidityNotificationOptions({
          id,
          status: 'success',
          content: `${addingLiquidityContent} ${t('succeeded')}`,
          step,
          hideExplorerLink: false,
          hashTx: firstHashTx,
        }),
      );

      // step 2
      step = { ...step2 };
      addNotification(
        getAddLiquidityNotificationOptions({
          id,
          status: 'inProgress',
          content: addingLiquidityContent,
          step,
          hideExplorerLink: true,
        }),
      );

      console.log(`waiting 'addLiquidity' cross-chain transaction`);
      const secondTx = await fetchSwapTransactionStatus(portfolio.chainId, firstTx.transactionHash);
      console.log(`has 'addLiquidity' cross-chain transaction : `, secondTx);

      await updatePortfolios();
      await updateSpicePointsBalance();

      const secondHashTx = secondTx.transactionStatus.txHashDestination;
      addNotification(
        getAddLiquidityNotificationOptions({
          id,
          status: 'success',
          content: `${addingLiquidityContent} ${t('succeeded')}`,
          step,
          hideExplorerLink: false,
          hashTx: secondHashTx,
        }),
      );
    } catch (error) {
      console.error('[PORTFOLIO:ADD:LIQUIDITY:CROSS:CHAIN] Happen error: ', error);
      addNotification(
        getAddLiquidityNotificationOptions({
          id,
          status: 'error',
          content: `${addingLiquidityContent} ${t('failed')}`,
          step,
          hideExplorerLink: true,
        }),
      );

      throw error;
    } finally {
      console.groupEnd();
    }
  }

  async function doAddLiquidity() {
    if (!addLiquidityForm.selectedTokens) return;

    let account = walletState.wallets[SELECTED_NETWORK_NAME].account;

    if (easyModeForm.portfolio.type === PortfolioSource.PORTFOLIO_CROSSCHAIN) {
      account = needDestinationAddress.value ? easyModeForm.destinationAddress : account;

      await addLiquidityCrossChain(
        easyModeForm as EasyModeAddLiquidityForm,
        easyModeForm.portfolio,
        addLiquidityForm.selectedTokens,
        account,
      );
      return;
    }
    await addLiquidityLocal(easyModeForm.portfolio, addLiquidityForm.selectedTokens, account);
  }

  watch(
    () => easyModeForm.destinationAddress,
    destinationAddress => {
      setDestinationAddressWSC(destinationAddress);
    },
  );

  return {
    needApproveTokens,
    addLiquidityForm,
    setSelectedTokens,
    checkAllowance,
    setAllowance,
    doAddLiquidity,
    $reset,
  };
});
