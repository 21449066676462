import _ from 'lodash';
import { computed, reactive } from 'vue';
import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { DEFAULT_CARDANO_CHAIN_ID } from '@/constants/DEFAULT_CARDANO_ID';
import { DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';
import { fromWei, getScanLink } from '@/sdk/utils';
import { calculateGasForReversBridge } from '@/helpers/milkomeda-wrapped-smartcontract/milkomeda-wsc-calculation';
import { Staking } from '@/sdk/entities/staking';
import { Token } from '@/sdk/entities/token';
import { IStakingHarvestForm } from '@/store/modules/staking/models/staking-harvest-form.interface';
import {
  INotification,
  NotificationStatus,
} from '@/store/modules/notifications/models/notification.interface';
import { BridgeToken } from '@/composables/milkomeda-wrapped-smartcontract/models/bridge-token';
import { StakingManualPoolTitle } from '@/store/modules/staking/models/staking-pool';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { useStakingMilkomedaWSCBridge } from '@/store/modules/staking/useStakingMilkomedaWSCBridge';
import { useStakingMilkomedaWSCUnwrapBridge } from '@/store/modules/staking/useStakingMilkomedaWSCUnwrapBridge';
import { useManualStakingTransactions } from '@/composables/staking/manual/useManualStakingTransactions';
import { useNotifications } from '@/store/modules/notifications/useNotifications';

export const useStakingHarvest = defineStore('stakingHarvest', () => {
  const { t } = useI18n();
  const { getTokenBySymbolAndChainId, isPresentTokenIntoNetwork } = useTokens();
  const {
    setBridgeTokensFromCardano,
    setIsEVMFromCardano,
    setHasBridgeFromMilkomeda,
    $reset: resetMilkomedaWSCBridge,
  } = useStakingMilkomedaWSCBridge();
  const {
    setBridgeTokensFromMilkomeda,
    setIsEVMFromMilkomeda,
    $reset: resetMilkomedaWSCUnwrapBridge,
  } = useStakingMilkomedaWSCUnwrapBridge();
  const { harvest } = useManualStakingTransactions();
  const { addNotification } = useNotifications();

  const stakingHarvestForm = reactive<IStakingHarvestForm>({
    pool: null,
    token: null,
  });

  const cardanoADATokenForBridge = computed<Token>(() => {
    return getTokenBySymbolAndChainId('ADA', +DEFAULT_CARDANO_CHAIN_ID);
  });

  function $reset(): void {
    stakingHarvestForm.pool = null;
    stakingHarvestForm.token = null;
  }

  function checkHarvestForBridgeFromMilkomeda(amountInWei: string, token: Token) {
    if (!ENABLE_FAKE_CARDANO_NETWORK) return;
    resetMilkomedaWSCBridge();
    resetMilkomedaWSCUnwrapBridge();

    const bridgeTokens: BridgeToken[] = [];
    if (isPresentTokenIntoNetwork(token.symbol!, +DEFAULT_CARDANO_CHAIN_ID)) {
      bridgeTokens.push({
        amount: fromWei(amountInWei, token.decimals).toString(),
        token: token,
      });
    }

    setIsEVMFromCardano(false);
    setHasBridgeFromMilkomeda(!!bridgeTokens.length);

    if (bridgeTokens.length) {
      setIsEVMFromMilkomeda(false);
      setBridgeTokensFromMilkomeda(bridgeTokens);
    } else {
      resetMilkomedaWSCUnwrapBridge();
    }

    const gas = calculateGasForReversBridge(cardanoADATokenForBridge.value, bridgeTokens);
    setBridgeTokensFromCardano(
      gas ? [gas] : [{ amount: '0', token: cardanoADATokenForBridge.value }],
    );
  }

  function setHarvestFromPool(pool: Staking, token: Token): void {
    stakingHarvestForm.pool = pool;
    stakingHarvestForm.token = token;
  }

  async function doHarvest() {
    console.groupCollapsed('[POOL:HARVEST] doHarvest');
    const cStakingHarvestForm: IStakingHarvestForm = _.cloneDeep(stakingHarvestForm);

    if (!cStakingHarvestForm.pool) return;
    if (!cStakingHarvestForm.token) return;

    const token = cStakingHarvestForm.token;
    const poolTitle = cStakingHarvestForm.pool.isSyrupPool ? 'syrup' : 'blues';
    const notificationId = `staking_harvest_${poolTitle}_${token.address}`;

    try {
      addNotification(
        getHarvestNotificationOptions(token, poolTitle, {
          id: notificationId,
          status: 'inProgress',
        }),
      );

      const transactionReceipt = await harvest(cStakingHarvestForm.pool);

      const explorerChainId = ENABLE_FAKE_CARDANO_NETWORK ? +DEFAULT_NETWORK_ID! : undefined;
      addNotification(
        getHarvestNotificationOptions(token, poolTitle, {
          id: notificationId,
          status: 'success',
          explorerLink: getScanLink(
            transactionReceipt.transactionHash,
            'transaction',
            explorerChainId,
          ),
        }),
      );
    } catch (error) {
      console.error(`[POOL:HARVEST] Happen error during harvest operation. ERROR : `, error);
      if (error.name === 'ProviderRpcError') {
        console.error(`[ERROR] ProviderRpcError. Error details : `, {
          code: error.code,
          data: error.data,
        });
      }

      addNotification(
        getHarvestNotificationOptions(token, poolTitle, {
          id: notificationId,
          status: 'error',
        }),
      );

      throw Error(error);
    } finally {
      console.groupEnd();
    }
  }

  // NOTIFICATIONS

  function getHarvestNotificationOptions(
    token: Token,
    poolTitle: StakingManualPoolTitle,
    options: {
      id: string;
      status: NotificationStatus;
      explorerLink?: string;
    },
  ): INotification {
    return {
      ...options,
      content: buildHarvestNotificationContent(token, poolTitle, options.status),
    };
  }

  function buildHarvestNotificationContent(
    token: Token,
    poolTitle: StakingManualPoolTitle,
    status: NotificationStatus,
  ): string {
    const poolTitleCaption = t(`yieldPool.manual.poolTitle.${poolTitle}.caption`, {
      name: token.name,
    });
    const harvestingFromPool = `${t('harvestingEarnings')} ${t('from')} ${poolTitleCaption}`;

    const notifications: Record<NotificationStatus, string> = {
      inProgress: harvestingFromPool,
      success: `${harvestingFromPool} ${t('succeeded')}`,
      error: `${harvestingFromPool} ${t('failed')}`,
    };

    return notifications[status];
  }
  // ====

  return {
    stakingHarvestForm,
    checkHarvestForBridgeFromMilkomeda,
    setHarvestFromPool,
    doHarvest,
    $reset,
  };
});
